import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useParams, useHistory} from 'react-router-dom';

import {
  CardContent,
  Button,
  Grid,
  Card,
  IconButton,
  Menu,
  Box,
  MenuItem,
} from '@material-ui/core';

import Place from '@material-ui/icons/Place';
import AccessTime from '@material-ui/icons/AccessTime';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ShareIcon from '@material-ui/icons/Share';

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';

import DefaultImg from 'assets/img/easy-planner-default-no-image.png';

import {TActivity} from 'types/activity';

export const CardItem = ({schedule}) => {
  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const {id} = useParams();
  const history = useHistory();
  const {t} = useTranslation();
  const currency = useSelector(state => state.config.symbol);

  const link = useMemo(
    () =>
      `${process.env.REACT_APP_URL_BASE}${id}/schedule/activity/${schedule.idactivity}`,
    [id, schedule.idactivity],
  );
  const title = useMemo(
    () => `${schedule.activity} - ${schedule.description}`,
    [schedule],
  );

  return (
    <>
      <Grid item xs={12} sm={6} md={4}>
        <Card className="mb-4">
          <button
            type="button"
            className="image-title-overlay"
            style={{cursor: 'pointer', padding: 0, border: 'none'}}
            onClick={() =>
              history.push(`/${id}/schedule/activity/${schedule.idactivity}`)
            }>
            <>
              <div style={{textAlign: 'right'}} className="card-badges">
                <span
                  style={{
                    marginBottom: '5px',
                    backgroundColor: 'white',
                  }}
                  className="h-auto px-1 py-1 badge badge badge-pill">
                  &nbsp;&nbsp; {schedule.place} &nbsp;
                  <Place fontSize="small" />
                </span>
                <br />
                <span
                  style={{
                    marginBottom: '5px',
                    backgroundColor: 'white',
                  }}
                  className="h-auto px-1 py-1 badge badge badge-pill">
                  &nbsp;&nbsp; {`${schedule.duration}  ${t('word.minutes')}`}{' '}
                  &nbsp;
                  <AccessTime fontSize="small" />
                  &nbsp;
                </span>
                <br />
                <span
                  style={{
                    marginBottom: '5px',
                    backgroundColor: 'white',
                  }}
                  className="h-auto px-1 py-1 badge badge badge-pill">
                  &nbsp;&nbsp;
                  {schedule.price <= 0 && (
                    <>
                      {t('word.free')} &nbsp;
                      <CheckCircleOutlineIcon fontSize="small" /> &nbsp;
                    </>
                  )}
                  {schedule.price > 0 && (
                    <>
                      {`${currency} ${schedule.price}`} &nbsp;
                      <MonetizationOnIcon fontSize="small" /> &nbsp;
                    </>
                  )}
                </span>
              </div>
              <div className="image-title-overlay--bottom">
                <h3 className="display-4 font-size-xs m-0 text-white text-left">
                  {schedule.activity}
                </h3>
              </div>
              <img
                style={{marginTop: '-3px'}}
                alt="activity"
                className="card-img-top"
                src={
                  schedule.photo
                    ? `data:${schedule.type};base64,${schedule.photo}`
                    : DefaultImg
                }
              />
            </>
          </button>
          <CardContent className="p-3">
            <p
              className="card-text text-justify"
              style={{whiteSpace: 'pre-line'}}>
              {schedule.description}
            </p>
            <IconButton
              size="small"
              aria-label="add"
              color="secondary"
              onClick={e => {
                setAnchorElMenu(e.currentTarget);
              }}>
              <ShareIcon />
            </IconButton>

            <div style={{float: 'right'}}>
              <Button
                color="primary"
                style={{
                  margin: '10px',
                  marginTop: '-5px',
                  marginRight: '-4px',
                  textTransform: 'none',
                }}
                variant="contained"
                onClick={() =>
                  history.push(
                    `/${id}/schedule/activity/${schedule.idactivity}`,
                  )
                }>
                {t('word.schedule')}
              </Button>
            </div>
          </CardContent>
        </Card>
      </Grid>

      <Menu
        anchorEl={anchorElMenu}
        open={Boolean(anchorElMenu)}
        onClose={() => setAnchorElMenu(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Box flexDirection="column" flexWrap="wrap">
          <MenuItem>
            <FacebookShareButton url={link} quote={title}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </MenuItem>
          <MenuItem>
            <TwitterShareButton url={link} title={title}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </MenuItem>
          <MenuItem>
            <WhatsappShareButton url={link} title={title}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </MenuItem>
        </Box>
      </Menu>
    </>
  );
};

CardItem.propTypes = {
  schedule: PropTypes.shape(TActivity).isRequired,
};
