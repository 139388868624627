import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  Divider,
  Dialog,
  TextField,
  List,
  ListItem,
} from '@material-ui/core';

import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import useAlert from 'hooks/useAlert';

import {editSchedule} from 'services/api';
import Enum from 'helpers/enum';

export const ModalCancel = ({visible, handleClose, dataSchedule}) => {
  const [loading, setLoading] = useState(true);
  const [confirmWarning, setConfirmWarning] = useState(false);
  const [reason, setReason] = useState('');
  const currency = useSelector(state => state.config.symbol);
  const {t} = useTranslation();
  const [openAlert] = useAlert();

  useEffect(() => {
    if (visible) {
      setLoading(false);
      setReason('');
    }
  }, [visible]);

  const handlePressClose = () => {
    setConfirmWarning(false);
    setReason('');
    setLoading(false);
    handleClose();
    if (confirmWarning === true) {
      setConfirmWarning(false);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (!loading) {
      setLoading(true);

      const {idschedule, status, priceToCancel, chargeCancel} = dataSchedule;

      try {
        const response = await editSchedule(
          idschedule,
          dataSchedule['person.customer.idcustomer'],
          status,
          Enum.schedule_status.canceled,
          reason,
          priceToCancel,
          chargeCancel,
          dataSchedule['professional.user.iduser'],
        );
        if (response.data.warningCancel === true) {
          handleClose();
        } else {
          openAlert(
            t('message.cancel-record', {param: t('word.schedule2')}),
            'success',
          );
          handleClose();
        }
      } catch (e) {
        if (e?.reponse?.data?.errors) {
          e?.reponse?.data?.errors.forEach(i => {
            openAlert(
              t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
              i.color,
            );
          });
        }
        handleClose();
      }

      setLoading(false);
    }
  };

  if (!visible || !dataSchedule) {
    return null;
  }

  return (
    <Dialog open={visible} onClose={handlePressClose}>
      <div className="text-center p-5">
        <div className="avatar-icon-wraper rounded-circle m-0">
          <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
            <span style={{fontSize: '60px', color: 'red'}}>x</span>
          </div>
        </div>
        <h4 className="font-weight-bold mt-4">
          {confirmWarning === false
            ? t('message.schedule-cancel')
            : t('message.update-values-cancel')}
        </h4>
        {((dataSchedule.chargeCancel && dataSchedule.priceToCancel) ||
          dataSchedule.warningCancel) && (
          <>
            <List>
              <ListItem className="d-flex justify-content-between align-items-center py-3">
                <div className="d-flex align-items-center">
                  <div>
                    <a
                      href="#/"
                      onClick={e => e.preventDefault()}
                      className="font-weight-bold text-black"
                      title="">
                      {t('message.cancel-schedule-app', {
                        param: `
                              ${currency}
                              ${dataSchedule.priceToCancel}`,
                      })}
                    </a>
                  </div>
                </div>
              </ListItem>
              <Divider className="opacity-6" />
            </List>
          </>
        )}
        <form onSubmit={handleSubmit}>
          {confirmWarning === false && (
            <Grid container align="left">
              <TextField
                label={t('message.schedule-cancel-reason')}
                id="standard-size-small"
                className="mt-4"
                size="small"
                required
                value={reason}
                onChange={e => setReason(e.target.value)}
                fullWidth
              />
            </Grid>
          )}
          <div className="pt-4">
            <Button
              disabled={loading}
              onClick={handleClose}
              size="large"
              variant="contained"
              className="mx-1">
              <span className="btn-wrapper--label">{t('word.no')}</span>
            </Button>
            <Button
              disabled={loading}
              type="submit"
              color="primary"
              size="large"
              variant="contained"
              className="mx-1">
              <span className="btn-wrapper--label">{t('word.yes')}</span>
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

ModalCancel.defaultProps = {
  dataSchedule: null,
};

ModalCancel.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  dataSchedule: PropTypes.shape({}),
};
