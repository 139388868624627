import React from 'react';
import PropTypes from 'prop-types';
import {Button, List, ListItem, Divider, Dialog} from '@material-ui/core';

import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {FaExclamation} from 'react-icons/fa';
import Enum from 'helpers/enum';

export default function ModalView({visible, handleClose, data}) {
  const {t} = useTranslation();
  const currency = useSelector(state => state.config.symbol);

  if (!visible || !data) {
    return null;
  }

  return (
    <>
      <Dialog open={visible} onClose={handleClose}>
        <div className="text-center p-5">
          <div className="avatar-icon-wraper rounded-circle m-0">
            <div
              style={{fontSize: '40px'}}
              className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-warning text-warning m-0 d-130">
              <span>
                <FaExclamation className="font-size-auto mx-auto" />
              </span>
            </div>
          </div>

          <h4 className="font-weight-bold mt-4">{t('word.policy-cancel')}</h4>
          {data.canceltimeout > 0 &&
            Enum.arrHaveCancelPolicy.includes(data.cancelpolicy) &&
            data.cancelvalue > 0 && (
              <p className="mb-0 font-size-lg text-muted text-black-50">
                {t(`message.cancel-time-policy`, {
                  param: data.canceltimeout,
                })}
              </p>
            )}
          <List>
            <>
              {data.canceltimeout > 0 &&
                Enum.arrHaveCancelPolicy.includes(data.cancelpolicy) &&
                data.cancelvalue > 0 && (
                  <>
                    <ListItem className="d-flex justify-content-between align-items-center py-3">
                      <div className="d-flex align-items-center">
                        <div>
                          <a
                            href="#/"
                            onClick={e => e.preventDefault()}
                            className="font-weight-bold text-black"
                            title="">
                            {t('message.after-period-cancellation', {
                              param: `
                          ${data.cancelpolicy === 2 ? currency : ''}
                          ${data.cancelvalue}
                          ${data.cancelpolicy === 1 ? '%' : ''}
                          `,
                            })}
                          </a>
                        </div>
                      </div>
                    </ListItem>
                    <Divider className="opacity-6" />
                  </>
                )}
              {Enum.arrHaveCancelPolicy.includes(data.noshowpolicy) &&
                data.noshowvalue > 0 && (
                  <>
                    <ListItem className="d-flex justify-content-between align-items-center py-3">
                      <div className="d-flex align-items-center">
                        <div>
                          <a
                            href="#/"
                            onClick={e => e.preventDefault()}
                            className="font-weight-bold text-black"
                            title="">
                            {t('message.noshow-message', {
                              param: `
                          ${data.noshowpolicy === 2 ? currency : ''}
                          ${data.noshowvalue}
                          ${data.noshowpolicy === 1 ? '%' : ''}
                          `,
                            })}
                          </a>
                        </div>
                      </div>
                    </ListItem>
                    <Divider className="opacity-6" />
                  </>
                )}
            </>
          </List>
          <div className="pt-4">
            <Button
              style={{height: '40px'}}
              onClick={handleClose}
              variant="contained"
              color="primary"
              className="mx-1">
              <span className="btn-wrapper--label">{t('word.Back')}</span>
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

ModalView.defaultProps = {
  data: null,
};

ModalView.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.shape({}),
};
