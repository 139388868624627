import React from 'react';
import PropTypes from 'prop-types';

import {Grid, CardContent} from '@material-ui/core';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './calendar.scss';

import {useTranslation} from 'react-i18next';

export const CalendarActivity = ({minDate, maxDate, onClickDay}) => {
  const {i18n} = useTranslation();
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      className="mb-2">
      <Grid item xs={12} lg={10}>
        <CardContent className="p-0">
          <Calendar
            minDate={minDate}
            maxDate={maxDate}
            locale={i18n.language}
            onClickDay={onClickDay}
          />
        </CardContent>
      </Grid>
    </Grid>
  );
};

CalendarActivity.defaultProps = {
  minDate: null,
  maxDate: null,
};

CalendarActivity.propTypes = {
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  onClickDay: PropTypes.func.isRequired,
};
