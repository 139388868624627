import React, {useEffect, useState} from 'react';

import {useTranslation} from 'react-i18next';

import {Grid, Typography} from '@material-ui/core';

import CardSkeleton from 'components/skeleton/cardSkeleton';

import {getActivities, getTypeActivities} from 'services/api';

import {CardItem} from '../cardItem';
import {Search} from '../search';

const CardList = () => {
  const {t} = useTranslation();
  const [activityListAll, setActivityListAll] = useState([]);
  const [activityListFiltered, setActivityListFiltered] = useState([]);
  const [loading, setLoading] = useState(true);
  const [typeActivitiesOptions, setTypeActivitiesOptions] = useState([]);
  const [typeActive, setTypeActive] = useState('');

  console.log('Variável de ambiente REACT_APP_API_BASE');
  console.log(process.env.REACT_APP_API_BASE);

  useEffect(() => {
    const getAllTypeActivities = async () => {
      console.log('getAllTypeActivities');
      try {
        const response = await getTypeActivities();
        console.log(response);
        setTypeActivitiesOptions(response.data);
        if (response.data) {
          setLoading(false);
        }
      } catch {
        // TODO
      }
    };

    const getAllActivities = async () => {
      console.log('getAllActivities');
      try {
        const response = await getActivities();
        console.log(response);
        setActivityListAll(response.data);
        setActivityListFiltered(response.data);
        if (response.data) {
          getAllTypeActivities();
        }
      } catch {
        setActivityListAll([]);
        setActivityListFiltered([]);
      }
    };

    getAllActivities();
  }, []);

  const handleSearch = e => {
    if (e.target.value === t('word.all')) {
      setTypeActive(t('word.all'));
      setActivityListFiltered(activityListAll);
    } else {
      setTypeActive(e.target.value);
      const {value} = e.target;
      let result = [];
      result = activityListAll.filter(row => row.idtypeactivity === value);
      setActivityListFiltered(result);
    }
  };

  if (loading) {
    return <CardSkeleton />;
  }

  if (!loading && activityListFiltered.length === 0) {
    return (
      <Grid container spacing={2} justifyContent="center" className="py-2">
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h4">
            {t('message.no-search-activities')}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {typeActivitiesOptions.length > 1 && loading === false && (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Search
                handleSearch={handleSearch}
                typeActive={typeActive}
                typeOptions={typeActivitiesOptions}
              />
            </Grid>
          </Grid>
        </>
      )}

      <Grid container spacing={2} justifyContent="center" className="py-2">
        {activityListFiltered &&
          activityListFiltered.map(i => (
            <CardItem schedule={i} key={i.idactivity} />
          ))}
      </Grid>
    </>
  );
};

export default CardList;
