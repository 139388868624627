import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Grid, Button} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';

import {useTheme} from '@material-ui/styles';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import Slider from 'react-slick';

import DefaultImg from 'assets/img/easy-planner-default-person.png';
import {TPeriod} from 'types/period';
import {TActivity} from 'types/activity';

export const Periods = ({
  activity,
  periods,
  date,
  handleChangeSelectedPeriod,
  loading,
}) => {
  const [selectedKey, setSelectedKey] = useState(null);
  const [selectedProf, setSelectedProf] = useState(null);

  const {t} = useTranslation();
  const theme = useTheme();

  const primaryColor = theme.palette.primary.main;

  useEffect(() => {
    if (loading) {
      setSelectedProf(null);
      setSelectedKey(null);
    }
  }, [date, loading]);

  const select = async (j, k) => {
    if (
      selectedKey === k &&
      selectedProf === j['professionals.idprofessional']
    ) {
      setSelectedProf(null);
      setSelectedKey(null);
      handleChangeSelectedPeriod(null);
      return;
    }
    setSelectedProf(j['professionals.idprofessional']);
    setSelectedKey(k);
    handleChangeSelectedPeriod(j);
  };

  if (!periods) {
    return null;
  }

  if (periods && periods.length === 0 && date && !loading) {
    return (
      <Grid container spacing={0} direction="column" className="mb-2">
        <Grid item xs={12} lg={12}>
          <MuiAlert className="m-4" severity="warning">
            <div className="d-flex align-items-center align-content-center">
              <span>
                <strong className="d-block">{t('word.care')}!</strong>
                {t('message.noperiod')}
              </span>
              <span
                style={{fontSize: '12px'}}
                className="ml-3 btn-pill m-1 badge badge-warning">
                {moment(date).format(t('format.date'))}
              </span>
            </div>
          </MuiAlert>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2} className="mb-2">
      <Grid item xs={12} lg={12}>
        <Slider slidesToShow={1} slidesToScroll={1} dots>
          {periods.map(i => (
            <div
              key={i.professional}
              className="card-box text-center p-2 mb-2 border-none">
              <div className="avatar-icon-wrapper rounded-circle mx-auto">
                <div className="d-block p-0 avatar-icon-wrapper rounded-circle m-0 border-3 border-first">
                  <div className="rounded-circle border-3 border-white overflow-hidden">
                    <img
                      alt=""
                      className="img-fluid"
                      src={
                        i.photo
                          ? `data:${i.mimetype};base64,${i.photo}`
                          : DefaultImg
                      }
                      style={{height: '50px', width: '50px'}}
                    />
                  </div>
                </div>
              </div>
              <h4 className="font-size-lg font-weight-bold my-2">
                {i.professional}
              </h4>
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                style={{marginTop: '-5px'}}>
                {i.periods[0]['professionals.user.occupation.occupation']}
              </Typography>

              <div className="d-flex flex-wrap justify-content-center">
                {i.periods.map((j, k) => (
                  <div
                    key={`${j['professionals.idprofessional']}-${j['periods.idperiod']}-${j['periods.starttime']}`}>
                    {(j.left > 0 || activity.simultaneous === 0) && (
                      <Button
                        variant={
                          selectedProf === j['professionals.idprofessional'] &&
                          selectedKey === k
                            ? 'contained'
                            : 'outlined'
                        }
                        color={
                          selectedProf === j['professionals.idprofessional'] &&
                          selectedKey === k
                            ? 'primary'
                            : 'primary'
                        }
                        className="m-2"
                        onClick={() => select(j, k)}>
                        <span className="btn-wrapper--label">
                          {`${j['periods.starttime']} ${t('word.at')} ${
                            j['periods.endtime']
                          }`}
                        </span>
                        {j.left > 0 && activity.simultaneous > 1 && (
                          <span
                            style={{
                              backgroundColor:
                                selectedProf ===
                                  j['professionals.idprofessional'] &&
                                selectedKey === k
                                  ? 'white'
                                  : primaryColor,
                              color:
                                selectedProf ===
                                  j['professionals.idprofessional'] &&
                                selectedKey === k
                                  ? primaryColor
                                  : '',
                            }}
                            className={
                              selectedProf ===
                                j['professionals.idprofessional'] &&
                              selectedKey === k
                                ? 'ml-3 btn-pill m-1 badge badge-info'
                                : 'ml-3 btn-pill m-1 badge badge-primary'
                            }>
                            <b>{activity.simultaneous <= 1 ? '' : j.left}</b>

                            {activity.simultaneous > 1 && j.left <= 1
                              ? ` ${t('word.remaining')}`
                              : ''}
                            {activity.simultaneous > 1 && j.left > 1
                              ? ` ${t('word.remainings')}`
                              : ''}
                          </span>
                        )}
                      </Button>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Slider>
      </Grid>
    </Grid>
  );
};

Periods.defaultProps = {
  periods: [],
  date: null,
  loading: false,
};

Periods.propTypes = {
  periods: PropTypes.arrayOf(PropTypes.shape(TPeriod)),
  activity: PropTypes.shape(TActivity).isRequired,
  date: PropTypes.instanceOf(Date),
  handleChangeSelectedPeriod: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
