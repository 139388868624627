import React, {useRef, useCallback} from 'react';
import {
  Grid,
  Input,
  InputLabel,
  InputAdornment,
  Typography,
  Card,
  CardContent,
  Button,
  FormControl,
  Container,
} from '@material-ui/core';
import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';

import {useTranslation} from 'react-i18next';
import {useHistory, Link, useParams} from 'react-router-dom';
import useAlert from 'hooks/useAlert';

import {Formik, Form, Field} from 'formik';

import {useAuth} from 'context/auth';
import {FormSchema} from './formValidation';

const SignInPage = () => {
  const formikRef = useRef(null);
  const {loading, signIn} = useAuth();

  const {t} = useTranslation();
  const history = useHistory();
  const {id} = useParams();
  const [openAlert] = useAlert();

  const handleSubmitForm = useCallback(
    values => {
      const signInValues = {...values, companyId: id};
      signIn(signInValues)
        .then(() => {
          formikRef.current?.setSubmitting(false);

          history.replace(`/${id}`);
        })
        .catch(e => {
          if (e?.response?.data?.errors) {
            e?.response?.data.errors.forEach(i => {
              openAlert(
                t(`message.${i.code}`, {field: t(`word.${i.field}`)}),
                i.color,
              );
            });
          }

          formikRef.current?.resetForm();
        });
    },
    [history, signIn, id, openAlert, t],
  );

  return (
    <Container maxWidth="sm">
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Typography variant="h2" className="mb-2">
            {t('word.login')}
          </Typography>
          <Formik
            innerRef={formikRef}
            enableReinitialize={false}
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={FormSchema()}
            validateOnMount={false}
            onSubmit={(values, {setSubmitting}) => {
              setSubmitting(true);

              handleSubmitForm(values);
            }}>
            {({
              isSubmitting,
              isValidating,
              touched,
              handleChange,
              handleBlur,
              values,
              errors,
            }) => (
              <Form noValidate>
                <Card>
                  <CardContent className="p-3">
                    <Grid container spacing={0} direction="column">
                      <Grid item lg={12}>
                        <div className="mb-3">
                          <FormControl fullWidth>
                            <InputLabel>{t('word.email')}</InputLabel>
                            <Field name="email">
                              {({field}) => (
                                <>
                                  <Input
                                    autoComplete="none"
                                    name={field.name}
                                    inputProps={{maxLength: 128}}
                                    value={values.email || ''}
                                    error={!!errors.email && !!touched.email}
                                    disabled={loading}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    startAdornment={
                                      <InputAdornment position="start">
                                        <MailOutlineTwoToneIcon />
                                      </InputAdornment>
                                    }
                                  />
                                </>
                              )}
                            </Field>
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item lg={12}>
                        <div className="mb-3">
                          <FormControl fullWidth>
                            <InputLabel>{t('word.password')}</InputLabel>
                            <Field name="password">
                              {({field}) => (
                                <>
                                  <Input
                                    autoComplete="none"
                                    name={field.name}
                                    inputProps={{maxLength: 64}}
                                    value={values.password || ''}
                                    error={
                                      !!errors.password && !!touched.password
                                    }
                                    disabled={loading}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="password"
                                    startAdornment={
                                      <InputAdornment position="start">
                                        <LockTwoToneIcon />
                                      </InputAdornment>
                                    }
                                  />
                                </>
                              )}
                            </Field>
                          </FormControl>
                        </div>
                      </Grid>

                      <Grid item lg={12}>
                        <div className="text-left">
                          <Link to={`/${id}/customer/signup`}>
                            {t('word.signup')}{' '}
                          </Link>
                        </div>

                        <div className="divider opacity-5 my-2" />

                        <div className="d-flex flex-direction-row justify-content-end p-0">
                          <Button
                            className="mr-1"
                            color="secondary"
                            disabled={isSubmitting || isValidating || loading}
                            variant="contained"
                            size="large"
                            onClick={() => history.push(`/${id}`)}>
                            {t('word.cancel')}
                          </Button>

                          <Button
                            color="primary"
                            disabled={isSubmitting || isValidating || loading}
                            type="submit"
                            variant="contained"
                            size="large">
                            {t('word.signin')}
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SignInPage;
