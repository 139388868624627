import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Grid, Card, CardContent, Button, Divider} from '@material-ui/core';
import {useTheme} from '@material-ui/styles';
import Place from '@material-ui/icons/Place';
import AccessTime from '@material-ui/icons/AccessTime';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import ModalPolicyCancel from 'components/modals/modalPolicyCancel';

import moment from 'moment';

import Enum from 'helpers/enum';

export const ScheduleItem = ({schedule, handlePressCancel}) => {
  const [modalPolicy, setModalPolicy] = useState(false);
  const dataScheduleViewPolicy = useMemo(
    () => ({
      canceltimeout: schedule['activity.canceltimeout'],
      cancelpolicy: schedule['activity.cancelpolicy'],
      cancelvalue: schedule['activity.cancelvalue'],
      noshowpolicy: schedule['activity.noshowpolicy'],
      noshowvalue: schedule['activity.noshowvalue'],
    }),
    [schedule],
  );

  const {t} = useTranslation();
  const theme = useTheme();
  const currency = useSelector(state => state.config.symbol);

  const handleOpenModalPolicy = () => {
    setModalPolicy(true);
  };

  const handleCloseModalPolicy = () => {
    setModalPolicy(false);
  };

  return (
    <Grid item xs={12} md={6} lg={4}>
      <ModalPolicyCancel
        visible={modalPolicy}
        handleClose={handleCloseModalPolicy}
        data={dataScheduleViewPolicy}
      />

      <Card className="mb-4">
        <div className="card-badges">
          {schedule.status === Enum.schedule_status.scheduled && (
            <span className="h-auto px-3 py-1 badge badge-info badge-pill">
              {t('word.scheduled')}
            </span>
          )}
          {schedule.status === Enum.schedule_status.canceled && (
            <span className="h-auto px-3 py-1 badge badge-danger badge-pill">
              {t('word.canceled')}
            </span>
          )}
          {schedule.status === Enum.schedule_status.inprogress && (
            <span className="h-auto px-3 py-1 badge badge-warning badge-pill">
              {t('message.in-progress')}
            </span>
          )}
          {schedule.status === Enum.schedule_status.finished && (
            <span className="h-auto px-3 py-1 badge badge-success badge-pill">
              {t('word.finished')}
            </span>
          )}
        </div>
        <div
          style={{
            textAlign: 'right',
            color: theme.palette.primary.main,
          }}
          className="card-badges">
          <span
            style={{
              marginTop: '37px',
              marginBottom: '5px',
              backgroundColor: 'white',
            }}
            className="h-auto px-1 py-1 badge badge badge-pill">
            &nbsp;&nbsp; {schedule[`activity.place`]} &nbsp;
            <Place fontSize="small" />
          </span>
          <br />
          <span
            style={{marginBottom: '5px', backgroundColor: 'white'}}
            className="h-auto px-1 py-1 badge badge badge-pill">
            &nbsp;&nbsp; {`${schedule.duration}  ${t('word.minutes')}`} &nbsp;
            <AccessTime fontSize="small" />
            &nbsp;
          </span>
          <br />
          <span
            style={{marginBottom: '5px', backgroundColor: 'white'}}
            className="h-auto px-1 py-1 badge badge badge-pill">
            &nbsp;&nbsp;
            {schedule.price <= 0 && (
              <>
                {t('word.free')} &nbsp;
                <CheckCircleOutlineIcon fontSize="small" /> &nbsp;
              </>
            )}
            {schedule.price > 0 && (
              <>
                {`${currency} ${schedule.price}`} &nbsp;
                <MonetizationOnIcon fontSize="small" /> &nbsp;
              </>
            )}
          </span>
        </div>
        <div className="image-title-overlay">
          <div className="image-title-overlay--bottom">
            <h3 className="display-4 m-0 text-white">
              {moment(schedule.date).format(t('format.date'))}
            </h3>
            <h3 className="display-4 font-weight-bold m-0 text-white">
              {Array.from(schedule.starttime).splice(0, 5)} {t('word.time')}{' '}
              {Array.from(schedule.endtime).splice(0, 5)}
            </h3>
            <h3 className="display-4 font-size-xs m-0 text-white">
              {schedule[`activity.activity`]}
            </h3>
          </div>
          {schedule.status === Enum.schedule_status.scheduled && (
            <img
              style={{marginTop: '-3px'}}
              alt=""
              className="card-img-top"
              src={
                schedule['activity.photo']
                  ? `data:${schedule['activity.mimetype']};base64,${schedule['activity.photo']}`
                  : 'easy-planner-default-no-image.png'
              }
            />
          )}
          {(schedule.status === Enum.schedule_status.inprogress ||
            schedule.status === Enum.schedule_status.finished) && (
            <img
              style={{marginTop: '-3px', opacity: 0.5}}
              alt=""
              className="card-img-top"
              src={
                schedule['activity.photo']
                  ? `data:${schedule['activity.mimetype']};base64,${schedule['activity.photo']}`
                  : 'easy-planner-default-no-image.png'
              }
            />
          )}
          {schedule.status === Enum.schedule_status.canceled && (
            <img
              style={{
                marginTop: '-3px',
                filter: 'grayscale(1)',
                opacity: '0.6',
              }}
              alt=""
              className="card-img-top"
              src={
                schedule['activity.photo']
                  ? `data:${schedule['activity.mimetype']};base64,${schedule['activity.photo']}`
                  : 'easy-planner-default-no-image.png'
              }
            />
          )}
        </div>

        <CardContent className="p-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <div>
                <b>{schedule[`professional.user.name`]}</b>
                <div className="text-black-50 text-size-xs">
                  {schedule[`professional.user.occupation.occupation`]}
                </div>
              </div>
            </div>
            <div className="d-block p-0 avatar-icon-wrapper rounded-circle m-0 border-3 border-first">
              <div className="rounded-circle border-3 border-white overflow-hidden">
                <img
                  alt=""
                  className="img-fluid"
                  src={
                    schedule[`professional.user.photo`]
                      ? `data:${
                          schedule[`professional.user.mimetype`]
                        };base64,${schedule[`professional.user.photo`]}`
                      : 'easy-planner-default-person.png'
                  }
                  style={{height: '50px', width: '50px'}}
                />
              </div>
            </div>
          </div>
          <Divider className="my-3" />
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <div>
                <b>{schedule['person.name']}</b>
                <div className="text-black-50 text-size-xs">
                  {t('word.participant')}
                </div>
              </div>
            </div>
          </div>
          <Divider className="my-3" />
          <div className="d-flex align-items-center justify-content-between">
            {(schedule['activity.canceltimeout'] > 0 &&
              Enum.arrHaveCancelPolicy.includes(
                schedule['activity.cancelpolicy'],
              ) &&
              schedule['activity.cancelvalue'] > 0) ||
            (Enum.arrHaveCancelPolicy.includes(
              schedule['activity.noshowpolicy'],
            ) &&
              schedule['activity.noshowvalue'] > 0) ? (
              <button
                type="button"
                onClick={() => handleOpenModalPolicy(schedule)}
                style={{
                  backgroundColor: '#FFF',
                  paddingTop: '20px',
                  fontSize: '12px',
                  color: 'gray',
                  textDecoration: 'underline gray',
                  cursor: 'pointer',
                  border: 'none',
                }}>
                {t('word.policy-cancel')}
              </button>
            ) : (
              <div
                style={{
                  paddingTop: '20px',
                  fontSize: '12px',
                  color: 'gray',
                }}>
                {t('word.no-policy-cancel')}
              </div>
            )}
            {schedule.status === Enum.schedule_status.scheduled &&
              schedule.editstatus === true && (
                <div className="font-weight-bold text-first font-size-lg">
                  <Button
                    onClick={() => handlePressCancel(schedule)}
                    color="secondary"
                    size="large"
                    variant="contained">
                    <small className="font-weight-bold">
                      {t('word.cancel')}
                    </small>
                  </Button>
                </div>
              )}
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

ScheduleItem.propTypes = {
  schedule: PropTypes.shape({}).isRequired,
  handlePressCancel: PropTypes.func.isRequired,
};
