import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

import {useTranslation} from 'react-i18next';
import {subscribeUser} from 'config/subscription';

import {loginRequest, logoutRequest, authenticateRequest} from 'services/api';
import {useParams} from 'react-router-dom';

const AuthContext = createContext();

const AuthProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [company, setCompany] = useState(null);
  const [pms, setPms] = useState(0);
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const params = useParams();

  const {i18n} = useTranslation();

  useEffect(() => {
    async function loadStorageData() {
      const tokenStorage = Cookies.get('planner-app-token');
      const userStorage = Cookies.get('planner-app-user');
      const companyStorage = Cookies.get('planner-app-company');
      const pmsStorage = Cookies.get('planner-app-pms');

      if (
        tokenStorage &&
        userStorage &&
        companyStorage &&
        companyStorage === params.id
      ) {
        setUser(JSON.parse(userStorage));
        setToken(tokenStorage);
        setCompany(companyStorage);
      }

      setPms(pmsStorage);
      setInitialized(true);
    }
    loadStorageData();
  }, [params]);

  const signIn = useCallback(
    async values => {
      setLoading(true);

      const {email, password, companyId} = values;

      await loginRequest(email, password)
        .then(response => {
          const {document, id, name, language, idaccountpms} = response.data;

          const userData = {
            document,
            id,
            name,
            idaccountpms,
            language,
          };

          setUser(userData);
          setToken(response.data.token);
          setCompany(companyId);

          Cookies.set('planner-app-token', response.data.token);
          Cookies.set('planner-app-user', JSON.stringify(userData));
          Cookies.set('planner-app-company', companyId);

          i18n.changeLanguage(language);

          setLoading(false);

          subscribeUser();

          return Promise.resolve();
        })
        .catch(e => {
          setLoading(false);

          return Promise.reject(e);
        });
    },
    [i18n],
  );

  const authenticate = useCallback(
    async values => {
      setLoading(true);

      const {lastname, uhpms, companyId} = values;

      await authenticateRequest(lastname, uhpms)
        .then(response => {
          const {document, id, name, language, idaccountpms} = response.data;

          const userData = {
            document,
            id,
            name,
            idaccountpms,
            language,
          };

          setUser(userData);
          setToken(response.data.token);
          setCompany(companyId);

          Cookies.set('planner-app-token', response.data.token);
          Cookies.set('planner-app-user', JSON.stringify(userData));
          Cookies.set('planner-app-company', companyId);

          i18n.changeLanguage(language);

          setLoading(false);

          subscribeUser();

          return Promise.resolve();
        })
        .catch(e => {
          setLoading(false);

          return Promise.reject(e);
        });
    },
    [i18n],
  );

  const signOut = useCallback(async () => {
    try {
      await logoutRequest();
    } catch {
      // TODO
    }

    Cookies.remove('planner-app-token');
    Cookies.remove('planner-app-user');

    setUser(null);
    setToken(null);

    return Promise.resolve();
  }, []);

  const updateUser = useCallback(
    userData => {
      const newUserData = {
        ...user,
        ...userData,
      };
      setUser(newUserData);
      Cookies.set('planner-app-user', JSON.stringify(newUserData));
    },
    [user],
  );

  const updateToken = useCallback(newToken => {
    setToken(newToken);
    Cookies.set('planner-app-token', newToken);
  }, []);

  const contextValue = useMemo(
    () => ({
      signed: !!user,
      user,
      token,
      company,
      loading,
      signIn,
      signOut,
      updateUser,
      updateToken,
      pms,
      authenticate,
    }),
    [
      user,
      token,
      company,
      loading,
      signIn,
      signOut,
      updateUser,
      updateToken,
      pms,
      authenticate,
    ],
  );

  return (
    <AuthContext.Provider value={contextValue}>
      {initialized ? <>{children}</> : <div>LOADING</div>}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.');
  }

  return context;
}

export {AuthProvider, useAuth};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
