export const Types = {
  CONFIG_SETCONFIG: 'config/CONFIG_SETCONFIG',
};

const INITIAL_STATE = {
  symbol: '',
};

export const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case Types.CONFIG_SETCONFIG: {
      const {symbol} = action.payload;
      return {
        ...state,
        symbol,
      };
    }
    default:
      return state;
  }
};

export const Creators = {
  setConfig: config => ({
    type: Types.CONFIG_SETCONFIG,
    payload: {
      ...config,
    },
  }),
};
