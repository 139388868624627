export const Types = {
  COMPANY_SETCOMPANY: 'company/COMPANY_SETCOMPANY',
};

const INITIAL_STATE = {
  id: null,
  name: '',
  img: null,
  pms: 0,
};

export const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case Types.COMPANY_SETCOMPANY: {
      const {id, name, img, pms} = action.payload;
      return {
        ...state,
        id,
        name,
        img,
        pms,
      };
    }
    default:
      return state;
  }
};

export const Creators = {
  setCompany: company => ({
    type: Types.COMPANY_SETCOMPANY,
    payload: {
      ...company,
    },
  }),
};
