import React from 'react';
import {Container} from '@material-ui/core';

import CardList from './components/cardList';

const HomePage = () => (
  <>
    <Container>
      <CardList />
    </Container>
  </>
);

HomePage.defaultProps = {};

HomePage.propTypes = {};

export default HomePage;
