import React, {Suspense} from 'react';

import {Switch, Route} from 'react-router-dom';
import {Fab} from '@material-ui/core';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import {ClimbingBoxLoader} from 'react-spinners';

import CardCookies from 'components/cardCookies';
import {CompanyWrapper} from 'hoc/CompanyWrapper';

import PagesError404 from '../pages/notFound';

import {ScrollTop} from './scrollTop';
import {AppNavigation} from './routes';

const SuspenseLoading = () => (
  <>
    <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
      <div className="d-flex align-items-center flex-column px-4">
        <ClimbingBoxLoader color="#5383ff" loading />
      </div>
      <div className="text-muted font-size-xl text-center pt-3">Carregando</div>
    </div>
  </>
);

const Routes = () => (
  <Suspense fallback={<SuspenseLoading />}>
    <Switch>
      <Route
        path={[
          '/:id',
          '/schedule/activity/:id',
          '/customer/signin',
          '/customer/signup',
          '/customer/profile',
          '/customer/account',
        ]}>
        <CompanyWrapper>
          <AppNavigation />
        </CompanyWrapper>

        <ScrollTop>
          <Fab color="secondary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>

        <CardCookies />
      </Route>

      <Route component={PagesError404} />
    </Switch>
  </Suspense>
);

export default Routes;
