import React from 'react';
import PropTypes from 'prop-types';

import {TextField, MenuItem} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

import {TActivityType} from 'types/typeActivity';

export const Search = ({handleSearch, typeActive, typeOptions}) => {
  const {t} = useTranslation();

  return (
    <TextField
      className="mb-2"
      id="activity"
      select
      label={t('word.type-activities')}
      value={typeActive}
      onChange={event => handleSearch(event)}
      fullWidth>
      {typeOptions.length > 1 && (
        <MenuItem key={t('word.all')} value={t('word.all')}>
          {t('word.all')}
        </MenuItem>
      )}
      {typeOptions.map(option => (
        <MenuItem key={option.idtypeactivity} value={option.idtypeactivity}>
          {option.typeactivity}
        </MenuItem>
      ))}
    </TextField>
  );
};

Search.defaultProps = {
  typeActive: null,
  typeOptions: [],
};

Search.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  typeActive: PropTypes.string,
  typeOptions: PropTypes.arrayOf(PropTypes.shape(TActivityType)),
};
