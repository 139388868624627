import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {useTranslation} from 'react-i18next';

import ModalPolicyCancel from 'components/modals/modalPolicyCancel';

import Enum from 'helpers/enum';
import {TActivity} from 'types/activity';

export const CancelPolicy = ({activity}) => {
  const [modalPolicy, setModalPolicy] = useState(false);
  const {t} = useTranslation();

  if (
    (activity.canceltimeout > 0 &&
      Enum.arrHaveCancelPolicy.includes(activity.cancelpolicy) &&
      activity.cancelvalue > 0) ||
    (Enum.arrHaveCancelPolicy.includes(activity.noshowpolicy) &&
      activity.noshowvalue > 0)
  ) {
    return (
      <>
        <ModalPolicyCancel
          visible={modalPolicy}
          handleClose={() => {
            setModalPolicy(false);
          }}
          data={activity}
        />
        <button
          type="button"
          onClick={() => setModalPolicy(!modalPolicy)}
          className="pb-2 pl-2"
          style={{
            backgroundColor: '#FFF',
            fontSize: '12px',
            color: 'gray',
            textDecoration: 'underline gray',
            cursor: 'pointer',
            border: 'none',
          }}>
          {t('word.policy-cancel')}
        </button>
      </>
    );
  }
  return (
    <div
      style={{
        paddingBottom: '20px',
        paddingLeft: '25px',
        fontSize: '12px',
        color: 'gray',
      }}>
      {t('word.no-policy-cancel')}
    </div>
  );
};

CancelPolicy.propTypes = {
  activity: PropTypes.shape(TActivity).isRequired,
};
