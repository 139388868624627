import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Grid, Button, TextField, MenuItem} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {useAuth} from 'context/auth';

import ModalFeedback from 'components/modals/modalFeedback';
import ModalListScheduleSimultaneous from 'components/modals/modalListScheduleSimultaneous';

import {getAllPerson, addSchedule} from 'services/api';

import {TActivity} from 'types/activity';
import useAlert from 'hooks/useAlert';
import Enum from 'helpers/enum';

export const ActivityFooter = ({
  activity,
  companyId,
  activityId,
  periodSelected,
  date,
  handleRefresh,
}) => {
  const [disable, setDisable] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [idperson, setIdPerson] = useState();
  const [listPerson, setListPerson] = useState([]);
  const [find, setFind] = useState(false);

  const {t} = useTranslation();
  const history = useHistory();
  const [openAlert] = useAlert();
  const {signed, pms} = useAuth();

  useEffect(() => {
    const getPersonAll = async () => {
      try {
        const responsePeople = await getAllPerson();
        setListPerson(responsePeople.data);
      } catch (e) {
        if (e?.response?.data?.errors) {
          e?.response?.data?.errors.forEach(i => {
            openAlert(
              t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
              i.color,
            );
          });
        }
        history.push(`/${companyId}`);
      }
    };

    if (signed) {
      getPersonAll();
    }
  }, [companyId, history, t, openAlert, signed]);

  const handleSubmit = async e => {
    e.preventDefault();
    setDisable(true);

    try {
      const response = await addSchedule(
        activityId,
        date,
        periodSelected['professionals.idprofessional'],
        periodSelected['periods.starttime'],
        periodSelected['periods.endtime'],
        idperson,
        find,
      );

      if (response.data.find) {
        setFind(true);
        setSchedule(response.data.schedules);
        setDisable(false);
      } else {
        setModalVisible(true);
      }
    } catch {
      // TODO
      setDisable(false);
    }
  };

  const handleConfirmSubmit = async () => {
    if (find === true) {
      try {
        const response = await addSchedule(
          activityId,
          date,
          periodSelected['professionals.idprofessional'],
          periodSelected['periods.starttime'],
          periodSelected['periods.endtime'],
          idperson,
          find,
        );
        if (response.data) {
          setModalVisible(true);
        }

        setFind(false);
        setSchedule([]);
        setDisable(false);
      } catch {
        // TODO
      }
    }
  };

  const handleReplace = go => {
    history.replace(go);
  };

  const handleReload = () => {
    setIdPerson();
    setDisable(false);
    setModalVisible(!modalVisible);
  };

  const handleCloseConfirm = () => {
    setFind(false);
    setSchedule([]);
    setDisable(false);
  };

  if (!periodSelected || !activity || !date || !activityId) {
    return null;
  }

  return (
    <Grid container spacing={2} className="mb-2">
      <ModalFeedback
        visible={modalVisible}
        date={date}
        handleReload={() => {
          handleReload();
          handleRefresh(date);
        }}
        periodSelected={periodSelected}
      />

      <ModalListScheduleSimultaneous
        list={schedule}
        visible={find}
        handleClose={() => {
          handleCloseConfirm();
        }}
        hadleSubmit={() => {
          handleConfirmSubmit();
        }}
      />

      <Grid item xs={12} lg={12}>
        <form onSubmit={handleSubmit} className="">
          {signed ? (
            <div>
              <div className="divider opacity-5 my-2" />

              <div className="d-flex flex-direction-row justify-content-end p-2">
                <TextField
                  className="m-2"
                  label={t('word.who')}
                  id="standard-size-small"
                  select
                  size="medium"
                  required
                  value={idperson || ''}
                  onChange={e => setIdPerson(e.target.value)}
                  disabled={disable}
                  fullWidth>
                  {listPerson &&
                    listPerson.map(i => (
                      <MenuItem value={i.idperson} key={i.idperson}>
                        {i.name}
                      </MenuItem>
                    ))}
                </TextField>
              </div>

              {activity.rules ? (
                <div className="d-flex flex-direction-row p-2">
                  <MuiAlert className="m-2" severity="info">
                    <div className="d-flex align-items-center align-content-center">
                      <span
                        style={{whiteSpace: 'pre-line'}}
                        className="text-justify">
                        <strong className="d-block">{t('word.rules')}</strong>
                        {activity.rules}
                      </span>
                    </div>
                  </MuiAlert>
                </div>
              ) : null}

              <div className="d-flex flex-direction-row justify-content-end p-3">
                <Button
                  disabled={disable}
                  type="submit"
                  className="mt-2"
                  color="primary"
                  variant="contained"
                  size="large">
                  {t('word.schedule')}
                </Button>
              </div>
            </div>
          ) : null}

          {!signed && pms !== Enum.pms.dsl45 ? (
            <div>
              <div className="divider opacity-5 my-2" />

              <div className="d-flex flex-direction-row justify-content-end p-2">
                <Button
                  className="mr-1"
                  disabled={disable}
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={() =>
                    handleReplace(`/${companyId}/customer/signup`)
                  }>
                  {t('word.signup')}
                </Button>
                <Button
                  disabled={disable}
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={() =>
                    handleReplace(`/${companyId}/customer/signin`)
                  }>
                  {t('word.login')}
                </Button>
              </div>
            </div>
          ) : null}

          {!signed && pms === Enum.pms.dsl45 ? (
            <div>
              <div className="divider opacity-5 my-2" />

              <div className="d-flex flex-direction-row justify-content-end p-2">
                <Button
                  className="mr-1"
                  disabled={disable}
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={() => handleReplace(`/${companyId}/customer/auth`)}>
                  {t('word.authenticate')}
                </Button>
              </div>
            </div>
          ) : null}
        </form>
      </Grid>
    </Grid>
  );
};

ActivityFooter.defaultProps = {
  activityId: null,
  date: null,
  periodSelected: null,
};

ActivityFooter.propTypes = {
  activity: PropTypes.shape(TActivity).isRequired,
  companyId: PropTypes.string.isRequired,
  activityId: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  periodSelected: PropTypes.shape({}),
  handleRefresh: PropTypes.func.isRequired,
};
