import axios from 'axios';
import Cookies from 'js-cookie';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  config => {
    const company = Cookies.get('planner-app-company');
    const token = Cookies.get('planner-app-token');
    const newConfig = {...config};
    newConfig.headers.company = company;
    newConfig.headers.token = token;
    return newConfig;
  },
  error => Promise.reject(error),
);

api.interceptors.response.use(
  response => {
    if (response.data?.companyNotAllowed) {
      // TODO
      // removeCompany();
      // RemoveCookiesCustomer();
      // window.location.href = '/';
    }
    if (response.data?.notAllowed) {
      // TODO
      // RemoveCookiesCustomer();
      // window.location.href = idcommpany() ? `/${idcommpany()}` : '/';
    }
    return response;
  },
  error => Promise.reject(error),
);

export default api;

export const loginRequest = async (email, password) =>
  api.post(`app/customer/login`, {
    email,
    password,
  });

export const authenticateRequest = async (lastname, uhpms) =>
  api.post(`app/customer/auth`, {
    lastname,
    uhpms,
  });

export const logoutRequest = async () => api.get(`app/customer/logout`);

export const getAllScheduleAfterDay = async () =>
  api.get(`app/customer/schedule/afterday`);

export const getAllScheduleDay = async () =>
  api.get(`app/customer/schedule/day`);

export const getConfigsCustomer = async () => api.get(`app/customer/configs`);

export const getActivity = async id => api.get(`app/activity/${id}`);

export const getAvailability = async (id, day, date, start, end) =>
  api.get(
    `app/availability/${id}?day=${day}&date=${date}&start=${start}&end=${end}`,
  );

export const getAllPerson = async () => api.get(`app/person/all`);

export const getConfigs = async () => api.get(`app/configuration/id`);

export const addSchedule = async (
  idactivity,
  date,
  idprofessional,
  starttime,
  endtime,
  idperson,
  confirm,
) =>
  api.post(`app/schedule/new`, {
    idactivity,
    date,
    idprofessional,
    starttime,
    endtime,
    idperson,
    confirm,
  });

export const getTheCompany = async id => api.get(`app/home/${id}`);

export const getActivities = async () => api.get('app/activities');

export const getTypeActivities = async () => api.get('app/typeactivity');

export const getSchedules = async () => api.get('app/schedule/customer');

export const editSchedule = async (
  idschedule,
  idcustomer,
  oldstatus,
  newstatus,
  reason,
  priceToCancel,
  chargeCancel,
  iduser,
) =>
  api.post(`app/schedule/edit/${idschedule}`, {
    idcustomer,
    oldstatus,
    newstatus,
    reason,
    priceToCancel,
    chargeCancel,
    iduser,
  });

export const getCustomer = async () => api.get(`app/customer`);

export const editCustomer = async (name, email, language, document) =>
  api.post(`app/customer/edit`, {
    name,
    email,
    language,
    document,
  });

export const editPassword = async (password, newpassword, confirmpassword) =>
  api.post(`app/customer/change/password`, {
    password,
    newpassword,
    confirmpassword,
  });

export const addCustomer = async (
  name,
  email,
  language,
  password,
  confirmpassword,
  document,
) =>
  api.post('app/customer/new', {
    name,
    email,
    language,
    password,
    confirmpassword,
    document,
  });
