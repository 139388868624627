import React from 'react';

import {Box} from '@material-ui/core';

const HeaderMenu = () => (
  <>
    <Box className="header-logo-text" />
  </>
);

export default HeaderMenu;
