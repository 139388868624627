import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
} from '@material-ui/core';

import {useTranslation} from 'react-i18next';
import useAlert from 'hooks/useAlert';

import {editPassword} from 'services/api';
import {useAuth} from 'context/auth';

export default function ModalEditPassword({visible, setModalVisible}) {
  const {t} = useTranslation();
  const close = () => setModalVisible(!visible);
  const [password, setPassword] = useState('');
  const [newpassword, setNewPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [disable, setDisable] = useState(false);
  const [openAlert] = useAlert();
  const {updateToken} = useAuth();

  useEffect(() => {
    if (visible) {
      setPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setDisable(false);
    }
  }, [visible]);

  const handleSubmit = async event => {
    event.preventDefault();
    setDisable(true);

    try {
      const response = await editPassword(
        password,
        newpassword,
        confirmpassword,
      );

      openAlert(
        t('message.edit-record-f', {param: t('word.password')}),
        'success',
      );

      setModalVisible(false);
      setPassword('');
      setNewPassword('');
      setConfirmPassword('');

      setDisable(false);

      updateToken(response.data.token);
    } catch (e) {
      if (e?.response?.data?.errors) {
        e?.response?.data?.errors.forEach(i => {
          openAlert(
            t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
            i.color,
          );
        });
      }
      setDisable(false);
    }
  };

  if (!visible) {
    return null;
  }

  return (
    <>
      <Dialog open={visible} onClose={close} fullWidth>
        <div className="text-center p-5">
          <Grid container spacing={2} direction="column">
            <Card>
              <CardContent>
                <h2>{t('word.my-account')}</h2>
                <form onSubmit={handleSubmit}>
                  <Grid item xs={12} lg={12}>
                    <TextField
                      type="password"
                      label={t('word.password')}
                      required
                      inputProps={{maxLength: 64}}
                      disabled={disable}
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <TextField
                      type="password"
                      label={t('word.new-password')}
                      required
                      inputProps={{maxLength: 64}}
                      disabled={disable}
                      value={newpassword}
                      onChange={e => setNewPassword(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <TextField
                      type="password"
                      label={t('word.confirm-password')}
                      required
                      disabled={disable}
                      inputProps={{maxLength: 64}}
                      value={confirmpassword}
                      onChange={e => setConfirmPassword(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} lg={12} />
                  <div className="pt-4">
                    <Button
                      size="large"
                      onClick={close}
                      variant="contained"
                      disabled={disable}
                      color="secondary"
                      className="mx-1">
                      <span className="btn-wrapper--label">
                        {t('word.cancel')}
                      </span>
                    </Button>
                    <Button
                      size="large"
                      onClick={handleSubmit}
                      variant="contained"
                      disabled={disable}
                      color="primary"
                      className="mx-1">
                      <span className="btn-wrapper--label">
                        {t('word.save')}
                      </span>
                    </Button>
                  </div>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </div>
      </Dialog>
    </>
  );
}

ModalEditPassword.propTypes = {
  visible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
};
