import {useSnackbar} from 'notistack';
import {useCallback} from 'react';

export default function useAlert() {
  const {enqueueSnackbar} = useSnackbar();

  const openAlert = useCallback(
    (msg, variant = 'info') => {
      enqueueSnackbar(msg, {
        variant,
      });
    },
    [enqueueSnackbar],
  );

  return [openAlert];
}
