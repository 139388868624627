import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  Divider,
} from '@material-ui/core';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

export default function ModalViewDetailsAccommodation({
  visible,
  setModalVisible,
  result,
  pms,
}) {
  const {t} = useTranslation();

  const close = () => setModalVisible(!visible);

  if (!visible) {
    return null;
  }

  const {hospedes} = result;

  return (
    <>
      <Dialog open={visible} onClose={close}>
        <PerfectScrollbar>
          <div className="text-center p-5">
            <Card>
              <CardContent>
                <form>
                  <div className="heading-3 my-2 pg-2 pb-2 pt-2">
                    {t('word.Details-my-accommodation')}
                  </div>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        color="primary"
                        component="div"
                        className="d-flex align-items-center">
                        <div className="text-first font-weight-bold">
                          {t('word.N-account')}
                        </div>
                        <div className="ml-auto font-size-xs" />
                      </Typography>
                      <TextField
                        className="m-2"
                        id="standard-select-currency"
                        required
                        disabled
                        value={result.idaccountpms || ''}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        color="primary"
                        component="div"
                        className="d-flex align-items-center">
                        <div className="text-first font-weight-bold">
                          {t('word.uhpms')}
                        </div>
                        <div className="ml-auto font-size-xs" />
                      </Typography>
                      <TextField
                        className="m-2"
                        id="standard-select-currency"
                        required
                        disabled
                        value={result.uhpms || ''}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        color="primary"
                        component="div"
                        className="d-flex align-items-center">
                        <div className="text-first font-weight-bold">
                          {t('word.typeaccountpms')}
                        </div>
                        <div className="ml-auto font-size-xs" />
                      </Typography>
                      <TextField
                        className="m-2"
                        id="standard-select-currency"
                        required
                        disabled
                        value={t(
                          `word.integrate-pms.${pms}.typeaccount.${result.typeaccountpms}`,
                        )}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        color="primary"
                        component="div"
                        className="d-flex align-items-center">
                        <div className="text-first font-weight-bold">
                          {t('word.statusaccountpms')}
                        </div>
                        <div className="ml-auto font-size-xs" />
                      </Typography>
                      <TextField
                        className="m-2"
                        id="standard-select-currency"
                        required
                        disabled
                        value={t(
                          `word.integrate-pms.${pms}.status.${result.statusaccountpms}`,
                        )}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        color="primary"
                        component="div"
                        className="d-flex align-items-center">
                        <div className="text-first font-weight-bold">
                          {t('word.checkinpms')}
                        </div>
                        <div className="ml-auto font-size-xs" />
                      </Typography>
                      <TextField
                        className="m-2"
                        id="standard-select-currency"
                        required
                        disabled
                        value={
                          result.checkinpms
                            ? `${moment(result.checkinpms).format(
                                t('format.date'),
                              )}, ${moment(result.checkinpms).format('LT')}`
                            : ''
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        color="primary"
                        component="div"
                        className="d-flex align-items-center">
                        <div className="text-first font-weight-bold">
                          {t('word.checkoutpms')}
                        </div>
                        <div className="ml-auto font-size-xs" />
                      </Typography>
                      <TextField
                        className="m-2"
                        id="standard-select-currency"
                        required
                        disabled
                        value={
                          result.checkoutpms
                            ? `${moment(result.checkoutpms).format(
                                t('format.date'),
                              )}, ${moment(result.checkoutpms).format('LT')}`
                            : ''
                        }
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <div className="heading-3 my-2 pg-2 pb-2 pt-2">
                    {t('word.linked-people')}
                  </div>

                  <List>
                    {hospedes &&
                      hospedes.map(i => (
                        <div key={i.idhospede}>
                          <ListItem className="d-flex justify-content-between align-items-center py-3">
                            <div className="d-flex align-items-center">
                              {/* <Avatar alt="" src={avatar2} className="mr-2" /> */}
                              <div>
                                <a
                                  href="#/"
                                  onClick={e => e.preventDefault()}
                                  className="font-weight-bold text-black"
                                  title="">
                                  {i.name}
                                </a>
                                <span className="text-black-50 d-block">
                                  {i.email}{' '}
                                  {i.main ? `(${t('word.person-main')})` : ''}
                                </span>
                              </div>
                            </div>
                          </ListItem>
                          <Divider className="opacity-6" />
                        </div>
                      ))}
                  </List>
                  <div className="pt-4">
                    <Button
                      size="large"
                      onClick={close}
                      variant="contained"
                      color="primary"
                      className="mx-1">
                      <span className="btn-wrapper--label">
                        {t('word.Back')}
                      </span>
                    </Button>
                  </div>
                </form>
              </CardContent>
            </Card>
          </div>
        </PerfectScrollbar>
      </Dialog>
    </>
  );
}

ModalViewDetailsAccommodation.defaultProps = {
  result: {},
};

ModalViewDetailsAccommodation.propTypes = {
  visible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  pms: PropTypes.number.isRequired,
  result: PropTypes.shape({}),
};
