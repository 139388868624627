import React from 'react';

import {Skeleton} from '@material-ui/lab';

import {Grid, makeStyles, Card} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    backgroundColor: '#cccccc',
  },
}));

const CardSkeleton = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} sm={12} md={12}>
        <Card className="mb-4">
          <Skeleton animation="wave" variant="rect" className={classes.media} />
          <Skeleton
            animation="wave"
            height={10}
            width="20%"
            style={{
              backgroundColor: '#cccccc',
              marginLeft: '15px',
              marginTop: 5,
              marginBottom: 6,
            }}
          />
          <Skeleton
            animation="wave"
            height={10}
            width="30%"
            style={{
              backgroundColor: '#cccccc',
              marginLeft: '15px',
              marginBottom: 2,
            }}
          />
          <>
            <Skeleton
              animation="wave"
              height={0}
              width="98%"
              style={{
                backgroundColor: '#cccccc',
                marginBottom: 6,
                marginTop: 0,
              }}
            />
            <Skeleton
              animation="wave"
              height={10}
              width="90%"
              style={{
                backgroundColor: '#cccccc',
                marginLeft: '15px',
                marginRight: '15px',
                marginBottom: 6,
              }}
            />
            <Skeleton
              animation="wave"
              height={10}
              width="85%"
              style={{
                backgroundColor: '#cccccc',
                marginLeft: '15px',
                marginRight: '15px',
                marginBottom: 6,
              }}
            />
            <Skeleton
              animation="wave"
              height={10}
              width="70%"
              style={{
                backgroundColor: '#cccccc',
                marginLeft: '15px',
                marginRight: '15px',
                marginBottom: 6,
              }}
            />
          </>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CardSkeleton;
