import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Grid, Card, Container} from '@material-ui/core';

import {useHistory, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import useAlert from 'hooks/useAlert';
import {useAuth} from 'context/auth';

import Enum from 'helpers/enum';

import {getActivity, getAvailability, getConfigsCustomer} from 'services/api';

import {ButtonHome} from 'components/buttonHome';
import OneSkeletonActivity from 'components/skeleton/oneSkeletonActivity';
import BackDrop from 'components/backdrop';

import {ActivityDetail} from './components/activityDetail';
import {CalendarActivity} from './components/activityCalendar';
import {CancelPolicy} from './components/activityCancelPolicy';
import {Periods} from './components/activityPeriods';
import {ActivityFooter} from './components/activityFooter';

const ActivityPage = ({pms}) => {
  const [overlayLoading, setOverlayLoading] = useState(false);
  const [activityDetail, setActivityDetail] = useState(null);
  const [periods, setPeriods] = useState([]);
  const [periodSelected, setPeriodSelected] = useState(null);
  const [date, setDate] = useState(null);
  const [customerConfigs, setCustomerConfigs] = useState(null);

  const {t} = useTranslation();
  const {idactivity, id} = useParams();
  const history = useHistory();
  const [openAlert] = useAlert();
  const {signed} = useAuth();

  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadConfig = async () => {
      try {
        const responseCustomerConfig = await getConfigsCustomer();
        setCustomerConfigs(responseCustomerConfig.data);
      } catch {
        // TODO
      }
    };

    const loadActivityData = async idActivity => {
      setLoading(true);

      if (signed) {
        loadConfig();
      }

      try {
        const response = await getActivity(idActivity);

        if (response.data) {
          setActivityDetail(response.data);
        }
      } catch (e) {
        if (e?.response?.data?.errors) {
          e?.response?.data?.errors.forEach(i => {
            openAlert(
              t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
              i.color,
            );
          });
        }
        history.push(`/${id}`);
      }

      setLoading(false);
    };

    loadActivityData(idactivity);
  }, [idactivity, history, t, openAlert, id, signed]);

  useEffect(() => {
    if (
      customerConfigs?.checkinpms &&
      customerConfigs?.checkoutpms &&
      Enum.arrPms.includes(pms)
    ) {
      const today = new Date();
      const dt = new Date(customerConfigs?.checkinpms);
      setMaxDate(new Date(customerConfigs?.checkoutpms));
      if (today > dt) {
        setMinDate(today);
      } else {
        setMinDate(t);
      }
    } else {
      setMinDate(new Date());
      setMaxDate();
    }
  }, [customerConfigs, pms, t]);

  const loadAvailability = async dt => {
    setOverlayLoading(true);
    setPeriods([]);
    const day = dt.getDay();
    try {
      const response = await getAvailability(idactivity, day, dt);
      setPeriods(response.data);
    } catch (e) {
      if (e?.response?.data?.errors) {
        e?.response?.data?.errors.forEach(i => {
          openAlert(
            t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
            i.color,
          );
        });
      }
      setPeriods([]);
    }

    setDate(dt);

    setPeriodSelected(null);
    setOverlayLoading(false);
  };

  if (loading) {
    return (
      <Container maxWidth="xs">
        <Grid container justifyContent="center" spacing={0} className="mb-2">
          <Grid item xs={12} lg={12}>
            <OneSkeletonActivity />
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Container maxWidth="xs">
      <Grid container justifyContent="center" spacing={0} className="mb-2">
        <Grid item xs={12} lg={12}>
          <Card className="mb-4">
            <BackDrop open={overlayLoading} />

            <ActivityDetail activity={activityDetail} />

            <CalendarActivity
              minDate={minDate}
              maxDate={maxDate}
              onClickDay={loadAvailability}
            />

            <Periods
              loading={overlayLoading}
              periods={periods}
              activity={activityDetail}
              date={date}
              handleChangeSelectedPeriod={value => {
                setPeriodSelected(value);
              }}
            />

            <ActivityFooter
              activity={activityDetail}
              companyId={id}
              activityId={idactivity}
              date={date}
              periodSelected={periodSelected}
              handleRefresh={loadAvailability}
            />

            <CancelPolicy activity={activityDetail} />
          </Card>
        </Grid>
      </Grid>
      <ButtonHome visible />
    </Container>
  );
};

export default ActivityPage;

ActivityPage.defaultProps = {
  customerConfigs: null,
  pms: null,
};

ActivityPage.propTypes = {
  customerConfigs: PropTypes.shape({}),
  pms: PropTypes.number,
};
