import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import {useTranslation} from 'react-i18next';

export default function Error({result}) {
  const {t} = useTranslation();

  return (
    <>
      {result.errors && (
        <Grid container direction="column" spacing={2}>
          {result.errors.map(i => (
            <Grid item xs={12} key={i.code}>
              <Alert severity="error">
                {t(`message.${i.code}`, {field: t(`word.${i.field}`)})}
              </Alert>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}

Error.defaultProps = {
  result: {
    errors: [],
  },
};

Error.propTypes = {
  result: PropTypes.shape({
    errors: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};
