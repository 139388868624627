import React, {useRef} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import './assets/base.scss';
import CssBaseline from '@material-ui/core/CssBaseline';
import {SnackbarProvider} from 'notistack';
import CloseIcon from '@material-ui/icons/Close';

import ScrollToTop from './utils/ScrollToTop';
import Routes from './routes';
import {store, persistor} from './store/configureStore';

const App = () => {
  console.log('App.js 1');
  const notistackRef = useRef();
  console.log('App.js 2');
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };
  console.log('App.js 3');

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <CssBaseline />
          <ScrollToTop>
            <SnackbarProvider
              autoHideDuration={6000}
              preventDuplicate
              maxSnack={4}
              ref={notistackRef}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              action={key => (
                <div className="hov" style={{marginRight: '10px'}}>
                  <CloseIcon
                    onClick={onClickDismiss(key)}
                    style={{fontSize: '15px'}}
                  />
                </div>
              )}>
              <Routes />
            </SnackbarProvider>
          </ScrollToTop>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
