import React from 'react';
import svgImage7 from 'assets/images/illustrations/500.svg';
import {Grid} from '@material-ui/core';

import {useTranslation} from 'react-i18next';

const NotFoundPage = () => {
  const {t} = useTranslation();
  return (
    <div className="flex-grow-1 w-100 d-flex align-items-center">
      <div className="bg-composed-wrapper--content">
        <div className="hero-wrapper bg-composed-wrapper">
          <div className="flex-grow-1 w-100 d-flex align-items-center">
            <Grid
              item
              lg={6}
              md={9}
              className="px-4 mx-auto text-center text-black">
              <img
                src={svgImage7}
                className="w-50 mx-auto d-block my-5 img-fluid"
                alt="..."
              />

              <h1 className="display-1 mb-3 px-4 font-weight-bold">404</h1>
              <h3 className="font-size-xxl line-height-sm font-weight-light d-block px-3 mb-3 text-black-50">
                {t('message.page-not-found')}
              </h3>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
