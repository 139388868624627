import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useHistory, useParams} from 'react-router-dom';

import {
  Container,
  Grid,
  Button,
  TextField,
  MenuItem,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';

import {useTranslation} from 'react-i18next';
import useAlert from 'hooks/useAlert';
import {useAuth} from 'context/auth';

import Backdrop from 'components/backdrop';
import ModalEditPassword from 'components/modals/modalEditPassword';
import ModalViewDetailsAccommodation from 'components/modals/modalViewDetailsAccommodation';

import moment from 'moment';

import {getCustomer, editCustomer} from 'services/api';
import Enum from '../../helpers/enum';

const languages = [
  {
    value: 'pt-BR',
    label: 'Português',
  },
  {
    value: 'es-ES',
    label: 'Espanhol',
  },
  {
    value: 'en-US',
    label: 'Inglês',
  },
];

const EditUserPage = ({pms}) => {
  const {t, i18n} = useTranslation();
  const history = useHistory();
  const {id} = useParams();
  const [openAlert] = useAlert();
  const {signed, updateUser} = useAuth();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [language, setLanguage] = useState('');
  const [modalEditPasswordVisible, setModalEditPasswordVisible] =
    useState(false);
  const [modalDetailVisible, setModalDetailVisible] = useState(false);
  const [resultDisabled, setResultDisabled] = useState({});
  const [document, setDocument] = useState('');
  const [integrateDate, setIntegrateDate] = useState('');
  const [created, setCreated] = useState('');

  useEffect(() => {
    const loadUserData = async () => {
      setLoading(true);
      try {
        const response = await getCustomer();

        setName(response.data.name);
        setEmail(response.data.email);
        setLanguage(response.data.language);
        setDocument(response.data.document);
        setCreated(response.data.created);
        setIntegrateDate(response.data.integrated);
        setResultDisabled(response.data);

        setLoading(false);
      } catch (e) {
        if (e?.response?.data?.errors) {
          e?.response?.data?.errors.forEach(i => {
            openAlert(
              t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
              i.color,
            );
          });
        }
        setLoading(false);
        history.replace(`/${id}`);
      }
    };

    if (signed) {
      loadUserData();
    } else {
      history.replace(`/${id}`);
    }
  }, [openAlert, t, history, id, signed]);

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    try {
      await editCustomer(name, email, language, document);

      i18n.changeLanguage(language);
      openAlert(t('message.edit-record', {param: t('word.user')}), 'success');

      updateUser({name});
    } catch (e) {
      if (e?.response?.data?.errors) {
        e?.response?.data?.errors.forEach(i => {
          openAlert(
            t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
            i.color,
          );
        });
      }
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="lg">
      <Backdrop open={loading} />

      <ModalEditPassword
        visible={modalEditPasswordVisible}
        setModalVisible={setModalEditPasswordVisible}
      />

      <ModalViewDetailsAccommodation
        result={resultDisabled}
        visible={modalDetailVisible}
        pms={pms}
        setModalVisible={setModalDetailVisible}
      />

      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} lg={12}>
          <Typography variant="h2">{t('word.my-account')}</Typography>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit} className="flex-grow-1">
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <TextField
                      label={t('word.name')}
                      required
                      disabled={loading}
                      value={name}
                      inputProps={{maxLength: 128}}
                      onChange={e => setName(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      type="email"
                      label={t('word.email')}
                      required
                      disabled={loading}
                      inputProps={{maxLength: 128}}
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      label={t('word.document')}
                      required
                      disabled={loading}
                      inputProps={{maxLength: 32}}
                      value={document}
                      onChange={e => setDocument(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      select
                      label={t('word.select-language')}
                      required
                      disabled={loading}
                      value={language}
                      onChange={e => setLanguage(e.target.value)}
                      fullWidth>
                      {languages.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <TextField
                      label={t('word.created')}
                      value={
                        created
                          ? `${moment(created).format(
                              t('format.date'),
                            )}, ${moment(created).format('LT')}`
                          : ''
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <TextField
                      label={t('word.PMS-consultation')}
                      value={
                        integrateDate
                          ? `${moment(integrateDate).format(
                              t('format.date'),
                            )}, ${moment(integrateDate).format('LT')}`
                          : ''
                      }
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} lg={12}>
                    <div className="divider opacity-5 my-2" />

                    <div className="d-flex flex-direction-row justify-content-end p-0">
                      <div className="d-flex justify-content-end p-0">
                        <Button
                          className="mr-2"
                          onClick={() =>
                            setModalEditPasswordVisible(
                              !modalEditPasswordVisible,
                            )
                          }
                          disabled={loading}
                          variant="contained"
                          color="secondary"
                          size="large">
                          {t('word.edit-password')}
                        </Button>
                        {Enum.arrPms.includes(pms) && (
                          <Button
                            onClick={() =>
                              setModalDetailVisible(!modalDetailVisible)
                            }
                            disabled={loading}
                            variant="contained"
                            color="secondary"
                            size="large">
                            {t('word.Details-my-accommodation')}
                          </Button>
                        )}
                      </div>

                      <div className="d-flex flex-grow-1 justify-content-end p-0">
                        <Button
                          className="mr-2"
                          onClick={() => history.push(`/${id}`)}
                          disabled={loading}
                          variant="contained"
                          size="large"
                          color="secondary">
                          {t('word.cancel')}
                        </Button>
                        <Button
                          onClick={handleSubmit}
                          disabled={loading}
                          type="submit"
                          color="primary"
                          variant="contained"
                          size="large">
                          {t('word.save')}
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EditUserPage;

EditUserPage.defaultProps = {
  pms: null,
};

EditUserPage.propTypes = {
  pms: PropTypes.number,
};
