import React, {useCallback, useEffect, useState} from 'react';

import {Grid, Container, Typography} from '@material-ui/core';

import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';

import useAlert from 'hooks/useAlert';
import {useAuth} from 'context/auth';

import {getSchedules} from 'services/api';

import ProfileSkeleton from 'components/skeleton/profileSkeleton';

import {ScheduleItem} from './components/scheduleItem';
import {ModalCancel} from './components/modalCancel';

const ProfilePage = () => {
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [dataSchedule, setDataSchedule] = useState(null);

  const history = useHistory();
  const {id} = useParams();
  const {t} = useTranslation();
  const {signed} = useAuth();

  const [openAlert] = useAlert();

  const getAllSchedules = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getSchedules();
      setLoading(false);
      setResult(response.data);
    } catch (e) {
      if (e?.response?.data?.errors) {
        e?.response?.data?.errors.forEach(i => {
          openAlert(
            t(`message.${i.code}`, {param: t(`word.${i.field}`)}),
            i.color,
          );
        });
      }
      history.replace(`/${id}`);
    }
  }, [history, id, t, openAlert]);

  useEffect(() => {
    if (signed) {
      getAllSchedules();
    } else {
      history.replace(`/${id}`);
    }
  }, [getAllSchedules, signed, history, id]);

  const handleClose = () => {
    setModalCancelVisible(false);

    getAllSchedules();
  };

  if (loading) {
    return <ProfileSkeleton />;
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <ModalCancel
          visible={modalCancelVisible}
          handleClose={() => {
            handleClose();
          }}
          dataSchedule={dataSchedule}
        />

        {result.length === 0 && (
          <Grid item xs={12} lg={12}>
            <Typography variant="h4">{t('message.no-schedules')}</Typography>
          </Grid>
        )}
        {result.map(i => (
          <ScheduleItem
            schedule={i}
            key={i.idschedule}
            handlePressCancel={() => {
              setDataSchedule(i);
              setModalCancelVisible(true);
            }}
          />
        ))}
      </Grid>
    </Container>
  );
};

export default ProfilePage;
