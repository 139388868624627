import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog, Button, List, ListItem, Divider} from '@material-ui/core';
import {FaExclamation} from 'react-icons/fa';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {useTranslation} from 'react-i18next';

export default function ModalListScheduleSimultaneous({
  visible,
  list,
  handleClose,
  hadleSubmit,
}) {
  const {t} = useTranslation();
  const [details, setDetails] = useState(true);

  if (!visible) {
    return null;
  }

  return (
    <>
      <Dialog open={visible} onClose={handleClose}>
        <PerfectScrollbar>
          <div className="text-center p-5">
            <div className="avatar-icon-wraper rounded-circle m-0">
              <div
                style={{fontSize: '40px'}}
                className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-warning text-warning m-0 d-130">
                <span>
                  <FaExclamation className="font-size-auto mx-auto" />
                </span>
              </div>
            </div>

            <h4 className="font-weight-bold mt-4">
              {t('message.confirm-schedule')}
            </h4>

            <Button
              color="primary"
              size="large"
              onClick={() => setDetails(!details)}>
              {details === false
                ? t('word.more-details')
                : t('word.less-details')}
            </Button>

            {details && (
              <List>
                {list &&
                  list.map(i => (
                    <div key={i.idschedule}>
                      <ListItem className="d-flex justify-content-between align-items-center py-3">
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="font-weight-bold text-black">
                              {i.one}
                            </span>
                            <span className="text-black-50 d-block">
                              {i.two} {i.three} {t('word.at')} {i.four}
                            </span>
                          </div>
                        </div>
                      </ListItem>
                      <Divider className="opacity-6" />
                    </div>
                  ))}
              </List>
            )}

            <p className="mb-0 font-size-lg text-muted text-black-50">
              {t('message.question-confirm-schedule')}
            </p>

            <div className="pt-4">
              <Button
                variant="outlined"
                size="large"
                color="secondary"
                className="mx-1"
                onClick={() => handleClose()}>
                <span className="btn-wrapper--label">{t('word.cancel')}</span>
              </Button>

              <Button
                variant="contained"
                size="large"
                color="primary"
                className="mx-1"
                onClick={() => hadleSubmit()}>
                <span className="btn-wrapper--label">{t('word.confirm')}</span>
              </Button>
            </div>
          </div>
        </PerfectScrollbar>
      </Dialog>
    </>
  );
}

ModalListScheduleSimultaneous.defaultProps = {
  list: [],
};

ModalListScheduleSimultaneous.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  hadleSubmit: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})),
};
