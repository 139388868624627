import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {useAuth} from 'context/auth';

import {useDispatch} from 'react-redux';
import {
  notificationRequest,
  Creators as NotificationActions,
} from 'store/ducks/notification';

import {Header} from '../../layout-components';

const LeftSidebar = ({children}) => {
  const dispatch = useDispatch();

  const {signed} = useAuth();

  useEffect(() => {
    if (!signed) {
      dispatch(NotificationActions.notificationClear());
    }
  }, [signed, dispatch]);

  useEffect(() => {
    async function bootstrapAsync() {
      dispatch(notificationRequest());
    }
    if (signed) {
      bootstrapAsync();
    }
  }, [signed, dispatch]);

  return (
    <>
      <div className={clsx('app-wrapper')}>
        <Header />
        <div className={clsx('app-content')}>
          <div className="app-content--inner">
            <div className="app-content--inner__wrapper">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

LeftSidebar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LeftSidebar;
