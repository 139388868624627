import Cookies from 'js-cookie';
import moment from 'moment';

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const convertedVapidKey = urlBase64ToUint8Array(
  'BBRXlsj6j0kdM1U9MO0GFrmmXV46-P8BTL4KNW23YOlwd7_nBUwPZWvBYDp2-VshZDeWtktrlUqd0sLvjiFEl5w',
);

function sendSubscription(subscription) {
  const imageDefault = 'fav.ico';

  return fetch(`${process.env.REACT_APP_API_BASE}notification`, {
    method: 'POST',
    body: JSON.stringify({
      subscription,
      date: moment().utc(true),
      icon: imageDefault,
    }),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      company: Cookies.get('planner-app-company'),
      token: Cookies.get('planner-app-token'),
    },
  });
}
// conditional render
const clicked = true;

export function subscribeUser() {
  if (clicked) {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then(registration => {
          if (!registration.pushManager) {
            console.log('Push manager unavailable.');
            return;
          }

          registration.pushManager
            .getSubscription()
            .then(existedSubscription => {
              if (existedSubscription === null) {
                console.log('No subscription detected, make a request.');
                registration.pushManager
                  .subscribe({
                    applicationServerKey: convertedVapidKey,
                    userVisibleOnly: true,
                  })
                  .then(newSubscription => {
                    console.log('New subscription added.', newSubscription);
                    sendSubscription(newSubscription);
                  })
                  .catch(e => {
                    if (Notification.permission !== 'granted') {
                      console.log('Permission was not granted.');
                    } else {
                      console.error(
                        'An error ocurred during the subscription process.',
                        e,
                      );
                    }
                  });
              } else {
                console.log('Existed subscription detected.');
                sendSubscription(existedSubscription);
              }
            });
        })
        .catch(e => {
          console.error(
            'An error ocurred during Service Worker registration.',
            e,
          );
        });
    }
  } else {
    console.log('Can not reachable to the service worker');
  }
}
