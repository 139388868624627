import React, {useState} from 'react';
import {Button, Snackbar} from '@material-ui/core';
import AvTimer from '@material-ui/icons/AvTimer';
import Cookies from 'js-cookie';

import {useTranslation} from 'react-i18next';

export default function CardCookies() {
  const [open, setOpen] = useState(!Cookies.get('cookie'));
  const {t} = useTranslation();

  const handleOpen = () => {
    Cookies.set('cookie', true);
    setOpen(false);
  };

  return (
    <>
      <Snackbar open={open} position="fixed">
        <div className="d-flex flex-row justify-content-between align-items-center card cookie p-3">
          <div className="d-flex flex-row align-items-center">
            <AvTimer fontSize="large" />
            <div className="ml-2 mr-2">
              <span>
                {t('message.privacy-warning', {
                  param: t('word.privacy-policy'),
                })}
                <br />
                <br />
                <a
                  href="https://www.desbravador.com.br/politica-de-privacidade"
                  target="_blank"
                  rel="noopener noreferrer">
                  {t('word.privacy-policy')}
                </a>
              </span>
            </div>
          </div>
          <div>
            <Button onClick={handleOpen} variant="contained" color="primary">
              {t('word.continue')}
            </Button>
          </div>
        </div>
      </Snackbar>
    </>
  );
}
