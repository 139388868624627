import React from 'react';
import PropTypes from 'prop-types';

import AccessTime from '@material-ui/icons/AccessTime';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Place from '@material-ui/icons/Place';

import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {TActivity} from 'types/activity';
import {useTheme} from '@material-ui/styles';

import ImgDefault from 'assets/img/easy-planner-default-no-image.png';

export const ActivityDetail = ({activity}) => {
  const currency = useSelector(state => state.config.symbol);
  const {t} = useTranslation();
  const theme = useTheme();

  return (
    <>
      <div
        style={{textAlign: 'right', color: theme.palette.primary.main}}
        className="card-badges">
        <span
          style={{marginBottom: '5px', backgroundColor: 'white'}}
          className="h-auto px-1 py-1 badge badge badge-pill">
          &nbsp;&nbsp; {activity.place} &nbsp;
          <Place fontSize="small" />
        </span>
        <br />
        <span
          style={{marginBottom: '5px', backgroundColor: 'white'}}
          className="h-auto px-1 py-1 badge badge badge-pill">
          &nbsp;&nbsp; {`${activity.duration}  ${t('word.minutes')}`} &nbsp;
          <AccessTime fontSize="small" />
          &nbsp;
        </span>
        <br />
        <span
          style={{marginBottom: '5px', backgroundColor: 'white'}}
          className="h-auto px-1 py-1 badge badge badge-pill">
          &nbsp;&nbsp;
          {activity.price <= 0 && (
            <>
              {t('word.free')} &nbsp;
              <CheckCircleOutlineIcon fontSize="small" /> &nbsp;
            </>
          )}
          {activity.price > 0 && (
            <>
              {`${currency} ${activity.price}`} &nbsp;
              <MonetizationOnIcon fontSize="small" /> &nbsp;
            </>
          )}
        </span>
      </div>
      <div className="image-title-overlay" title="">
        <div className="image-title-overlay--bottom">
          <h3 className="display-4 font-size-xs m-0 text-white">
            {activity.activity}
          </h3>
        </div>
        <img
          alt=""
          className="card-img-top"
          src={
            activity.photo
              ? `data:${activity.type};base64,${activity.photo}`
              : ImgDefault
          }
          style={{marginTop: '-4px', maxHeight: '350px'}}
        />
      </div>
    </>
  );
};

ActivityDetail.propTypes = {
  activity: PropTypes.shape(TActivity).isRequired,
};
