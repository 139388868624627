/* eslint-disable no-console */
import {useEffect} from 'react';

import {useAuth} from 'context/auth';

import api from 'services/api';
import {useTranslation} from 'react-i18next';

export const Interceptor = () => {
  const {user, signOut} = useAuth();
  const {t} = useTranslation();

  useEffect(() => {
    if (!user) {
      api.interceptors.response.clear();
    } else {
      api.interceptors.response.clear();
      api.interceptors.response.use(
        response => response,
        error => {
          if (
            error?.response?.status === 401 ||
            error?.response?.status === 403 ||
            error?.response?.data?.notAllowed
          ) {
            setTimeout(() => {
              signOut();
            }, 800);
          }

          return Promise.reject(error);
        },
      );
    }

    return () => {
      api.interceptors.response.clear();
    };
  }, [t, signOut, user]);

  return null;
};
