import React from 'react';
import clsx from 'clsx';
import {Hidden, IconButton, AppBar, Box, Tooltip} from '@material-ui/core';

import {useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {useTheme} from '@material-ui/styles';

import DesbravadorLogo from 'assets/img/desbravador-logo.png';

import HeaderUserbox from '../HeaderUserbox';
import HeaderMenu from '../HeaderMenu';
import HeaderDots from '../HeaderDots';

import 'assets/base.scss';

const Header = () => {
  const name = useSelector(state => state.company?.name);
  const img = useSelector(state => state.company?.img?.data);
  const type = useSelector(state => state.company?.img?.type);

  const history = useHistory();
  const theme = useTheme();
  const {id} = useParams();

  return (
    <>
      <AppBar
        style={{backgroundColor: theme.palette.primary.main, cursor: 'pointer'}}
        className={clsx('app-header')}
        position="fixed"
        elevation={11}>
        <Box className="app-header-toolbar">
          <Hidden>
            <Tooltip arrow title={name} placement="right">
              <Box
                onClick={() => {
                  history.push(`/${id}`);
                }}
                className="app-logo-wrapper">
                {img && (
                  <img
                    style={{maxHeight: '65px'}}
                    alt=""
                    src={img ? `data:${type};base64,${img}` : DesbravadorLogo}
                  />
                )}
                {!img && name && (
                  <Box className="header-logo-text hov">{name}</Box>
                )}
              </Box>
            </Tooltip>
          </Hidden>
          <Hidden mdDown>
            <Box className="d-flex align-items-center">
              <HeaderMenu />
            </Box>
          </Hidden>
          <Box className="d-flex align-items-center">
            <HeaderDots />
            <HeaderUserbox />
            <Box className="toggle-sidebar-btn-mobile">
              <Tooltip title="Toggle Sidebar" placement="right">
                <IconButton color="inherit" onClick={() => {}} size="medium" />
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </AppBar>
    </>
  );
};

Header.propTypes = {};

export default Header;
