import React, {useState} from 'react';
import {
  Avatar,
  Box,
  Badge,
  Menu,
  Button,
  List,
  ListItem,
  Divider,
  Snackbar,
} from '@material-ui/core';
import {FaAngleDown} from 'react-icons/fa';

import MuiAlert from '@material-ui/lab/Alert';
import ListIcon from '@material-ui/icons/List';
import {withStyles} from '@material-ui/core/styles';

import {useTranslation} from 'react-i18next';
import {useAuth} from 'context/auth';
import {useHistory, useParams} from 'react-router-dom';

import imageDefault from 'assets/img/easy-planner-default-person.png';
import Enum from 'helpers/enum';

const StyledBadge = withStyles({
  badge: {
    backgroundColor: 'var(--success)',
    color: 'var(--success)',
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})(Badge);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function HeaderUserbox() {
  const {t} = useTranslation();
  const history = useHistory();

  const [alertOpen, setAlertOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const {signed, signOut, user, pms} = useAuth();
  const {id} = useParams();

  const handleLogout = async () => {
    signOut()
      .then(() => {
        setAnchorEl(null);
        setAlertOpen(true);

        history.replace(`/${id}`);
      })
      .catch(() => [
        // TODO
      ]);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAlert = reason => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  return (
    <>
      <Box className="d-flex align-items-center">
        <Snackbar
          open={alertOpen}
          autoHideDuration={5000}
          onClose={handleCloseAlert}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <>
            <Alert onClose={handleCloseAlert}>
              {t('message.logout-success')}
            </Alert>
          </>
        </Snackbar>
      </Box>

      <Button
        color="inherit"
        onClick={handleClick}
        className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
        <Box>
          {signed ? (
            <>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot">
                <Avatar sizes="44" alt="avatar" src={imageDefault} />
              </StyledBadge>
              <span className="pl-1 pl-xl-3">
                <FaAngleDown className="opacity-5" />
              </span>
            </>
          ) : (
            <ListIcon />
          )}
        </Box>
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={handleClose}
        className="ml-2">
        <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
          <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
            {signed && (
              <>
                <Box>
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    variant="dot">
                    <Avatar sizes="44" alt="user" src={imageDefault} />
                  </StyledBadge>
                </Box>
              </>
            )}

            {signed && (
              <>
                <span
                  style={{paddingTop: '5px'}}
                  className="text-black-50 text-center">
                  {user?.name}
                </span>
                <Divider className="w-100 mt-2" />
                <ListItem
                  button
                  onClick={() => {
                    setAnchorEl(null);
                    history.push(`/${id}/customer/profile`);
                  }}>
                  {t('word.profile')}
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    setAnchorEl(null);
                    history.push(`/${id}/customer/account`);
                  }}>
                  {t('word.my-account')}
                </ListItem>
                <Divider className="w-100 mt-2" />
                <ListItem button onClick={handleLogout}>
                  {t('word.logout')}
                </ListItem>
              </>
            )}

            {!signed && pms !== Enum.pms.dsl45 && (
              <>
                <ListItem
                  button
                  onClick={() => {
                    setAnchorEl(null);
                    history.push(`/${id}/customer/signin`);
                  }}>
                  {t('word.login')}
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    setAnchorEl(null);
                    history.push(`/${id}/customer/signup`);
                  }}>
                  {t('word.signup')}
                </ListItem>
              </>
            )}

            {!signed && pms === Enum.pms.dsl45 && (
              <>
                <ListItem
                  button
                  onClick={() => {
                    setAnchorEl(null);
                    history.push(`/${id}/customer/auth`);
                  }}>
                  {t('word.authenticate')}
                </ListItem>
              </>
            )}
          </List>
        </div>
      </Menu>
    </>
  );
}
