import {getAllScheduleAfterDay, getAllScheduleDay} from 'services/api';

export const Types = {
  NOTIFICATION_REQUEST: 'notification/NOTIFICATION_REQUEST',
  NOTIFICATION_SUCCESS: 'notification/NOTIFICATION_SUCCESS',
  NOTIFICATION_ERROR: 'notification/NOTIFICATION_ERROR',
  NOTIFICATION_CLEAR: 'notification/NOTIFICATION_CLEAR',
};

const INITIAL_STATE = {
  loading: false,
  scheduleAfterDay: [],
  scheduleDay: [],
};

export const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case Types.NOTIFICATION_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case Types.NOTIFICATION_SUCCESS: {
      const {scheduleAfterDay, scheduleDay} = action.payload;
      return {
        ...state,
        loading: false,
        scheduleAfterDay,
        scheduleDay,
      };
    }
    case Types.NOTIFICATION_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case Types.NOTIFICATION_CLEAR: {
      return {
        ...state,
        scheduleAfterDay: [],
        scheduleDay: [],
      };
    }
    default:
      return state;
  }
};

export const Creators = {
  notificationRequest: () => ({
    type: Types.NOTIFICATION_REQUEST,
    payload: {},
  }),
  notificationSuccess: ({scheduleAfterDay, scheduleDay}) => ({
    type: Types.NOTIFICATION_SUCCESS,
    payload: {
      scheduleAfterDay,
      scheduleDay,
    },
  }),
  notificationError: () => ({
    type: Types.NOTIFICATION_ERROR,
    payload: {},
  }),
  notificationClear: () => ({
    type: Types.NOTIFICATION_CLEAR,
    payload: {},
  }),
};

export const notificationRequest = () => async dispatch => {
  dispatch(Creators.notificationRequest());

  try {
    const response1 = await getAllScheduleAfterDay();

    const response2 = await getAllScheduleDay();

    const notificationData = {
      scheduleAfterDay: response1.data,
      scheduleDay: response2.data,
    };
    dispatch(Creators.notificationSuccess(notificationData));

    return Promise.resolve(notificationData);
  } catch (e) {
    dispatch(Creators.notificationError());

    return Promise.reject(e);
  }
};
