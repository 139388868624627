import React, {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {
  IconButton,
  Box,
  Hidden,
  Popover,
  Button,
  Tooltip,
  Divider,
  Grid,
} from '@material-ui/core';
import CalendarTodayOutlined from '@material-ui/icons/CalendarTodayOutlined';
import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';

import {useTranslation} from 'react-i18next';

import PerfectScrollbar from 'react-perfect-scrollbar';

import {FaArrowRight} from 'react-icons/fa';
import moment from 'moment';

import DefaultImg from 'assets/img/easy-planner-default-person.png';
import {useSelector} from 'react-redux';

export default function HeaderDots() {
  const {t} = useTranslation();
  const history = useHistory();
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const {scheduleDay, scheduleAfterDay} = useSelector(
    state => state.notification,
  );

  const {id} = useParams();

  const handleClick1 = event => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClick2 = event => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);

  const handleOpenProfile = () => {
    setAnchorEl1(null);
    setAnchorEl2(null);
    history.push(`/${id}/customer/profile`);
  };

  return (
    <>
      <div className="d-flex align-items-center popover-header-wrapper">
        {scheduleDay && scheduleDay.length > 0 && (
          <div className="d-flex align-items-center popover-header-wrapper">
            <Hidden>
              <Box component="span" pr="2">
                <IconButton
                  onClick={handleClick1}
                  color="inherit"
                  className="btn-inverse mx-1 d-50">
                  <div
                    style={{backgroundColor: 'yellow', color: 'black'}}
                    className="badge badge-pill badge-warning badge-header">
                    {scheduleDay.length}
                  </div>
                  <NotificationsActiveTwoToneIcon />
                </IconButton>
                <Popover
                  open={open1}
                  anchorEl={anchorEl1}
                  onClose={handleClose1}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  classes={{
                    paper: 'app-header-dots',
                  }}>
                  <Grid container spacing={0}>
                    <div className="popover-custom-xl overflow-hidden">
                      <Grid item xs={12} lg={12}>
                        <div className="bg-composed-wrapper bg-midnight-bloom border-0 text-center rounded-sm m-2">
                          <div className="bg-composed-img-3 bg-composed-wrapper--image" />
                          <div className="bg-composed-wrapper--content text-light px-2 py-4">
                            <h4 className="font-size-xl font-weight-bold mb-2">
                              {t('word.Notifications')}
                            </h4>
                            <p className="opacity-8 mb-0">
                              {t('word.you-have')}{' '}
                              <b className="text-success">
                                {scheduleDay.length}
                              </b>{' '}
                              {scheduleDay.length > 1
                                ? t('word.schedules-for-day')
                                : t('word.schedule-for-day')}
                            </p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <div className="mx-2" />
                        <div className="height-280">
                          <PerfectScrollbar>
                            {scheduleDay &&
                              scheduleDay.map(i => (
                                <div
                                  className="timeline-list timeline-list-offset timeline-list-offset-dot"
                                  key={`${i['professional.user.name']}-${i['activity.activity']}-${i.starttime}`}>
                                  <div className="timeline-item">
                                    <div className="timeline-item-offset">
                                      {i.starttime.slice(0, 5)}
                                    </div>
                                    <div className="timeline-item--content">
                                      <Tooltip
                                        arrow
                                        title={t(
                                          `word.schedule-status.${i.status}`,
                                        )}
                                        placement="right">
                                        <div
                                          className={`timeline-item--icon bg-${t(
                                            `word.schedule-status-color.${i.status}`,
                                          )}`}
                                        />
                                      </Tooltip>
                                      <h4 className="timeline-item--label mb-2 font-weight-bold">
                                        <>
                                          {i['activity.activity']}
                                          <div
                                            className={`badge badge-pill mt-2 mb-2 ml-2 badge-neutral-${t(
                                              `word.schedule-status-color.${i.status}`,
                                            )} text-${t(
                                              `word.schedule-status-color.${i.status}`,
                                            )}`}>
                                            {t(
                                              `word.schedule-status.${i.status}`,
                                            )}
                                          </div>
                                        </>
                                      </h4>
                                      {i['activity.place']}
                                      <br />
                                      {`${t('word.Participant')}: ${
                                        i['person.name']
                                      }`}
                                      <div className="avatar-wrapper-overlap mt-2 mb-1">
                                        <div className="avatar-icon-wrapper avatar-icon-sm">
                                          <div className="avatar-icon">
                                            <Tooltip
                                              arrow
                                              title={
                                                i['professional.user.name']
                                              }
                                              placement="right">
                                              <img
                                                alt=""
                                                src={
                                                  i['user.photo']
                                                    ? `data:${i['professional.user.mimetype']};base64,${i['user.photo']}`
                                                    : DefaultImg
                                                }
                                              />
                                            </Tooltip>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </PerfectScrollbar>
                        </div>
                      </Grid>
                      <Divider />
                      <div className="text-center py-3">
                        <Button
                          color="primary"
                          variant="contained"
                          className="px-4"
                          onClick={() => handleOpenProfile()}>
                          <span className="btn-wrapper--label">
                            {t('word.profile')}
                          </span>
                          <span className="btn-wrapper--icon">
                            <FaArrowRight />
                          </span>
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Popover>
              </Box>
            </Hidden>
          </div>
        )}

        {scheduleAfterDay && scheduleAfterDay.info && (
          <div className="d-flex align-items-center popover-header-wrapper">
            <Hidden>
              <Box component="span" pr="2">
                <IconButton
                  onClick={handleClick2}
                  color="inherit"
                  className="btn-inverse mx-1 d-50">
                  <div className="badge badge-pill badge-primary badge-header">
                    {scheduleAfterDay.info.count}
                  </div>
                  <CalendarTodayOutlined />
                </IconButton>
                <Popover
                  open={open2}
                  anchorEl={anchorEl2}
                  onClose={handleClose2}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  classes={{
                    paper: 'app-header-dots',
                  }}>
                  <Grid container spacing={0}>
                    <div className="popover-custom-xl overflow-hidden">
                      <Grid item xs={12} lg={12}>
                        <div className="bg-composed-wrapper bg-midnight-bloom border-0 text-center rounded-sm m-2">
                          <div className="bg-composed-img-3 bg-composed-wrapper--image" />
                          <div className="bg-composed-wrapper--content text-light px-2 py-4">
                            <h4 className="font-size-xl font-weight-bold mb-2">
                              {t('word.Notifications')}
                            </h4>
                            <p className="opacity-8 mb-0">
                              {t('word.you-have')}{' '}
                              <b className="text-success">
                                {scheduleAfterDay.info.count}
                              </b>{' '}
                              {t('word.schedule-for-next-days')}
                            </p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <div className="mx-2" />
                        <div className="height-280">
                          <PerfectScrollbar>
                            {scheduleAfterDay.schedule &&
                              scheduleAfterDay.schedule.map(i => (
                                <div key={i.date}>
                                  <div className="timeline-list timeline-list-offset timeline-list-offset-dot">
                                    <div className="timeline-item">
                                      <h6
                                        className="timeline-item--label"
                                        style={{fontSize: '15px'}}>
                                        {moment(i.date).format(
                                          t('format.date'),
                                        )}
                                      </h6>
                                    </div>
                                  </div>
                                  {i.schedules &&
                                    i.schedules.map(j => (
                                      <div
                                        key={`${j['activity.activity']}-${j['person.name']}`}>
                                        <div className="timeline-list timeline-list-offset timeline-list-offset-dot">
                                          <div className="timeline-item-offset">
                                            {j.starttime.slice(0, 5)}
                                          </div>
                                          <div className="timeline-item">
                                            <div className="timeline-item--content">
                                              <Tooltip
                                                arrow
                                                title={t(
                                                  `word.schedule-status.${j.status}`,
                                                )}
                                                placement="right">
                                                <div
                                                  className={`timeline-item--icon bg-${t(
                                                    `word.schedule-status-color.${j.status}`,
                                                  )}`}
                                                />
                                              </Tooltip>
                                              <h4 className="timeline-item--label">
                                                {i['activity.activity']}
                                                <div
                                                  className={`badge badge-pill mt-2 mr-2 badge-neutral-${t(
                                                    `word.schedule-status-color.${j.status}`,
                                                  )} text-${t(
                                                    `word.schedule-status-color.${j.status}`,
                                                  )}`}>
                                                  {t(
                                                    `word.schedule-status.${j.status}`,
                                                  )}
                                                </div>
                                              </h4>
                                              {j['activity.place']}
                                              <br />
                                              {`${t('word.Participant')}: ${
                                                j['person.name']
                                              }`}
                                              <div className="avatar-wrapper-overlap mt-2 mb-1">
                                                <div className="avatar-icon-wrapper avatar-icon-sm">
                                                  <div className="avatar-icon">
                                                    <Tooltip
                                                      arrow
                                                      title={
                                                        j[
                                                          'professional.user.name'
                                                        ]
                                                      }
                                                      placement="right">
                                                      <img
                                                        alt=""
                                                        src={
                                                          j['user.photo']
                                                            ? `data:${j['professional.user.mimetype']};base64,${j['user.photo']}`
                                                            : DefaultImg
                                                        }
                                                      />
                                                    </Tooltip>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              ))}
                          </PerfectScrollbar>
                        </div>
                      </Grid>
                      <Divider />
                      <div className="text-center py-3">
                        <Button
                          color="primary"
                          variant="contained"
                          className="px-4"
                          onClick={() => handleOpenProfile()}>
                          <span className="btn-wrapper--label">
                            {t('word.profile')}
                          </span>
                          <span className="btn-wrapper--icon">
                            <FaArrowRight />
                          </span>
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Popover>
              </Box>
            </Hidden>
          </div>
        )}
      </div>
    </>
  );
}

HeaderDots.defaultProps = {};

HeaderDots.propTypes = {};
