import React, {Fragment} from 'react';
import {Skeleton} from '@material-ui/lab';
import {Container, Grid, makeStyles, Card} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '100%',
    height: 'auto',
  },
  media: {
    height: 345,
    paddingTop: '56.25%', // 16:9
    backgroundColor: '#cccccc',
  },
}));

const list = [0, 1, 3, 4];

const ProfileSkeleton = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        {list.map(i => (
          <Grid item xs={12} md={6} lg={4} key={i}>
            <Card
              style={{marginBottom: '20px', cursor: 'pointer'}}
              className={classes.root}>
              <Skeleton
                animation="wave"
                variant="rect"
                className={classes.media}
              />
              <>
                <Skeleton
                  animation="wave"
                  height={10}
                  width="100%"
                  style={{
                    backgroundColor: '#cccccc',
                    marginBottom: 15,
                    marginTop: 55,
                  }}
                />
                <Skeleton
                  animation="wave"
                  height={10}
                  width="80%"
                  style={{backgroundColor: '#cccccc', marginBottom: 6}}
                />
              </>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ProfileSkeleton;
