import React from 'react';
import ReactDOM from 'react-dom';

import './config/i18n';

import App from './App';

// import * as serviceWorker from './config/serviceWorker';

console.log('Index.js antes do ReactDOM');

ReactDOM.render(<App />, document.getElementById('root'));
// serviceWorker.register();

console.log('Index.js depois do ReactDOM');
