const Enum = {
  pms: {
    none: '0',
    dsl41: '1',
    dsl45: '2',
  },
  schedule_status: {
    scheduled: 0,
    canceled: 1,
    inprogress: 2,
    finished: 3,
  },
  active: 1,
  inactive: 0,
  arrPms: [1, 2],
  arrHaveCancelPolicy: [1, 2],
};

export default Enum;
