import React from 'react';
import PropTypes from 'prop-types';
import {Fab} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';

import {useHistory, useParams} from 'react-router-dom';

export const ButtonHome = ({visible}) => {
  const history = useHistory();
  const {id} = useParams();

  if (!visible) {
    return null;
  }

  return (
    <div
      style={{
        right: '51px',
        bottom: '8px',
        position: 'fixed',
      }}>
      <Fab
        color="primary"
        size="small"
        aria-label="add"
        className="m-2 card-box-hover-rise-alt"
        onClick={() => history.replace(`/${id}`)}>
        <HomeIcon />
      </Fab>
    </div>
  );
};

ButtonHome.propTypes = {
  visible: PropTypes.bool.isRequired,
};
