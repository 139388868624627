import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

import {Creators as CompanyActions} from 'store/ducks/company';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Backdrop from 'components/backdrop';
import Error from 'components/error';

import {getTheCompany} from 'services/api';

import {useHistory, useParams} from 'react-router-dom';

export const CompanyWrapper = ({children}) => {
  const [loading, setLoading] = useState(false);
  const [configured, setConfigured] = useState(false);
  const [errors, setErrors] = useState(null);

  const {id} = useParams();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const loadCompanyData = async companyId => {
      Cookies.set('planner-app-company', companyId);
      setLoading(true);
      try {
        const response = await getTheCompany(companyId);

        Cookies.set('planner-app-pms', response.data.pms);

        const company = {
          name: response.data.company,
          id: response.data.idcompany,
          pms: response.data.pms,
          img: {
            data: response.data.photo,
            type: response.data.mimetype,
          },
        };
        dispatch(CompanyActions.setCompany(company));

        setLoading(false);
        setConfigured(true);
      } catch (e) {
        if (e?.response?.data?.errors || e?.response?.data?.companyNotAllowed) {
          setErrors(e?.response?.data.errors);
        }
        setLoading(false);
        setConfigured(false);
      }
    };
    if (id) {
      loadCompanyData(id);
    } else {
      history.replace('/');
    }
  }, [dispatch, t, id, history]);

  if (!configured && errors) {
    return <Error result={{errors}} />;
  }

  return (
    <>
      <Backdrop open={loading} />
      {configured ? <>{children}</> : null}
    </>
  );
};

CompanyWrapper.defaultProps = {
  children: null,
};

CompanyWrapper.propTypes = {
  children: PropTypes.node,
};
