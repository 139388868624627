import React, {useState} from 'react';

import {
  Container,
  Grid,
  Button,
  TextField,
  MenuItem,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';

import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import useAlert from 'hooks/useAlert';

import {addCustomer} from 'services/api';

const languages = [
  {
    value: 'pt-BR',
    label: 'Português',
  },
  {
    value: 'es-ES',
    label: 'Espanhol',
  },
  {
    value: 'en-US',
    label: 'Inglês',
  },
];

const SignUpPage = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const {id} = useParams();
  const [openAlert] = useAlert();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [language, setLanguage] = useState('pt-BR');
  const [disable, setDisable] = useState(false);
  const [document, setDocument] = useState('');

  const handleSubmit = async event => {
    event.preventDefault();
    setDisable(true);

    try {
      await addCustomer(
        name,
        email.toLowerCase(),
        language,
        password,
        confirmPassword,
        document,
      );

      openAlert(
        t('message.customer-registration', {param: t('word.record')}),
        'success',
      );

      setDisable(false);

      history.replace(`/${id}/customer/signin`);
    } catch (e) {
      if (e?.response?.data?.errors) {
        e?.response?.data.errors.forEach(i => {
          openAlert(
            t(`message.${i.code}`, {field: t(`word.${i.field}`)}),
            i.color,
          );
        });
      }

      setDisable(false);
    }
  };

  return (
    <Container maxWidth="lg">
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} lg={12}>
          <Typography variant="h2">{t('word.signup')}</Typography>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <TextField
                      label={t('word.name')}
                      required
                      disabled={disable}
                      value={name}
                      onChange={e => setName(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      type="email"
                      label={t('word.email')}
                      required
                      inputProps={{maxLength: 128}}
                      disabled={disable}
                      value={email.toLowerCase()}
                      onChange={e => setEmail(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      label={t('word.document')}
                      required
                      disabled={disable}
                      value={document}
                      inputProps={{maxLength: 32}}
                      onChange={e => setDocument(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      type="password"
                      label={t('word.password')}
                      required
                      disabled={disable}
                      inputProps={{maxLength: 64}}
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      type="password"
                      label={t('word.confirm-password')}
                      required
                      disabled={disable}
                      value={confirmPassword}
                      inputProps={{maxLength: 64}}
                      onChange={e => setConfirmPassword(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      select
                      label={t('word.select-language')}
                      required
                      disabled={disable}
                      value={language}
                      onChange={e => setLanguage(e.target.value)}
                      fullWidth>
                      {languages.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} lg={12}>
                    <div className="divider opacity-5 my-2" />

                    <div className="d-flex flex-direction-row justify-content-end p-0">
                      <Button
                        className="mr-2"
                        color="secondary"
                        variant="contained"
                        size="large"
                        onClick={() => history.replace(`/${id}`)}>
                        {t('word.cancel')}
                      </Button>

                      <Button
                        color="primary"
                        type="submit"
                        variant="contained"
                        size="large">
                        {t('word.signup')}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SignUpPage;
